import React from "react";
import { Link } from "react-router-dom";
import "../styles/auth.scss";

const Terms = () => {
  return (
    <div className="terms min-w-screen min-h-screen py-10 md:py-16 bg-main">
      <div className="container mx-auto">
        <Link to={"/"}>
          <img width="260" alt="Building Bridges Logo" src="/images/logo-white.png" />
        </Link>
        <div className="bg-white rounded-3xl p-10 mt-10">
          <div className="">
            <div className="headingx text-5xl font-bold tracking-wide text-main">Terms and Conditions</div>

            <hr className="my-7" />
            <ul className="list-inside space-y-10 text-justify content">
              <li>
                <h2>Scope</h2>
                <p>These Terms and Conditions apply to the use of the Building Bridges platform, an online platform that connects brands with influencers and provides campaign tracking tools.</p>
              </li>

              <li>
                <h2>Access to the Platform</h2>
                <p>Access to the Building Bridges platform is restricted to commercial entities that are registered with Building Bridges. Registration is free of charge.</p>
              </li>

              <li>
                <h2>Use of the Platform</h2>
                <p>The Building Bridges platform may only be used for commercial purposes to connect brands with influencers and track campaigns. The platform may not be used for any other purpose, including personal or non-commercial use.</p>
              </li>

              <li>
                <h2>Content of the Platform</h2>
                <p>The content of the Building Bridges platform is provided by Building Bridges. Building Bridges strives to keep the content of the platform as accurate and up-to-date as possible. However, Building Bridges cannot guarantee that the content of the platform is always completely accurate or up-to-date.</p>
              </li>

              <li>
                <h2>Intellectual Property Rights</h2>
                <p>All intellectual property rights to the Building Bridges platform, including the software, content, and trademarks, belong to Building Bridges. It is not permitted to copy, distribute, translate, or otherwise use the content of the platform without the prior written permission of Building Bridges.</p>
              </li>

              <li>
                <h2>Liability</h2>
                <p>Building Bridges is not liable for any damage, direct or indirect, that is caused by the use of the Building Bridges platform. Building Bridges is also not liable for any damage that is caused by the use of the data that is obtained via the Building Bridges platform.</p>
              </li>

              <li>
                <h2>Registration</h2>
                <p>To access the Building Bridges platform, you must register with Building Bridges. Registration is free of charge. When registering, you must provide your company name, contact information, and a password.</p>
              </li>

              <li>
                <h2>Age Restrictions</h2>
                <p>People under the age of 18 cannot create an account on the Building Bridges platform due to legal requirements. This is to comply with the Child Online Privacy Protection Act (COPPA), which prohibits the collection of personal information from children under the age of 13 without parental consent.</p>
              </li>

              <li>
                <h2>Acceptance of Registration</h2>
                <p>Access to the Building Bridges platform is only possible if Building Bridges accepts the registration. Building Bridges reserves the right to refuse registration to any entity that it deems to be ineligible for use of the platform.</p>
              </li>

              <li>
                <h2>Modification of Terms and Conditions</h2>
                <p>Building Bridges reserves the right to modify these Terms and Conditions at any time. Modified Terms and Conditions will be published on the Building Bridges website.</p>
              </li>

              <li>
                <h2>Governing Law</h2>
                <p>These Terms and Conditions are governed by Dutch law.</p>
              </li>

              <li>
                <h2>Language</h2>
                <p>The official language for the Building Bridges platform is English.</p>
              </li>

              <li>
                <h2>Dispute Resolution</h2>
                <p>Any disputes arising from these Terms and Conditions will be resolved by binding arbitration in accordance with the Rules of Arbitration of the International Chamber of Commerce (ICC). The seat of arbitration will be Amsterdam, the Netherlands.</p>
              </li>

              <li>
                <h2>Entire Agreement</h2>
                <p>These Terms and Conditions constitute the entire agreement between the parties with respect to the subject matter hereof and supersede all prior or contemporaneous communications, representations, or agreements, whether oral or written.</p>
              </li>

              <li>
                <h2>Waiver</h2>
                <p>No waiver of any provision of these Terms and Conditions will be effective unless in writing and signed by Building Bridges.</p>
              </li>

              <li>
                <h2>Severability</h2>
                <p>If any provision of these Terms and Conditions is held to be invalid or unenforceable, such provision will be struck from these Terms and Conditions and the remaining provisions will remain in full force and effect.</p>
              </li>

              <li>
                <h2>Assignment</h2>
                <p>These Terms and Conditions may not be assigned by you without the prior written consent of Building Bridges. Building Bridges may assign these Terms and Conditions at any time without notice to you.</p>
              </li>

              <li>
                <h2>Notices</h2>
                <p>
                  All notices and other communications hereunder shall be in writing and shall be deemed to have been duly given when delivered in person, upon the first business day following deposit in the mail, postage prepaid, certified or registered, return receipt requested, addressed as follows:
                  <br />
                  <div className="p-4 rounded-lg bg-gray-100 mt-2 border border-gray-200" role="alert">
                    Building Bridges
                    <br />
                    Irenestraat 37 3433 CR, Nieuwegein, Netherlands
                  </div>
                </p>
              </li>

              <li>
                <h2>Counterparts</h2>
                <p>This Agreement may be executed in one or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
