import React, { useState } from 'react';

const Tooltip = ({ children, text }) => {
    const [isShown, setIsShown] = useState(false);

    return (
        <div className="relative flex items-center">
            <div
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
            >
                {children}
            </div>
            <div 
                className={`absolute w-44 max-w-[12rem] bottom-full mb-1 px-3 py-2 bg-neutral-900 leading-relaxed text-white text-xs rounded-md font-light shadow-md transition-opacity duration-300 ${isShown ? 'opacity-100' : 'opacity-0'}`}
                style={{ pointerEvents: isShown ? 'auto' : 'none' }} dangerouslySetInnerHTML={{ __html: text }}
            >
            </div>
        </div>
    );
};

export default Tooltip;
