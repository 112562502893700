import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Modal from "../components/Modal";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { PiPlusCircle } from "react-icons/pi";
import "../styles/campaigns.scss";
import Swal from 'sweetalert2';
import { FiChevronDown } from "react-icons/fi";
import { TfiTrash } from "react-icons/tfi";
import { RiEditLine } from "react-icons/ri";
import { FiEdit3 } from "react-icons/fi";
import { HiMiniChevronDown } from "react-icons/hi2";
import { GoDotFill } from "react-icons/go";

const Team = () => {
  const { getlists, addnewcampaign, updatemycampaign, deletemycampaign, getmycampaign, getmycampaigns, userToken, isLoading } = useContext(AppContext);

  const [campaigns, setCampaigns] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [campaign, setCampaign] = useState('');
  const [lists, setLists] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showNewModal, setshowNewModal] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [listName, setListName] = useState('');
  const [loadingMore, setLoadingMore] = useState(false);
  const [newCampaignName, setnewCampaignName] = useState('');
  const [newStartDate, setnewStartDate] = useState('');
  const [newEndDate, setnewEndDate] = useState('');
  const [newList, setnewList] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [eformLoading, seteFormLoading] = useState(false);
  const [eCampaignName, seteCampaignName] = useState('');
  const [eStartDate, seteStartDate] = useState('');
  const [eEndDate, seteEndDate] = useState('');
  const [eList, seteList] = useState('');
  const [eStatus, seteStatus] = useState('');
  const [iseFormChanged, setIseFormChanged] = useState(false);
  const [editing, setEditing] = useState(false);
  const [toEdit, settoEdit] = useState('');
  const [toDelete, settoDelete] = useState('');
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const fetchCampaigns = async () => {
    const response = await getmycampaigns(statusFilter);
    setCampaigns(response);
  };

  useEffect(() => {
    // fetchCampaigns();
  }, [statusFilter]);

  const fetchLists = async () => {
    const response = await getlists();
    setLists(response);
  };

  useEffect(() => {
    fetchLists();
    fetchCampaigns();
  }, []);

  const handleAddNewCampaign = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    await addnewcampaign(newCampaignName, newStartDate, newEndDate, newList);
    setshowNewModal(false);
    setnewCampaignName('');
    setnewStartDate('');
    setnewEndDate('');
    setnewList('');
    setFormLoading(false);
    fetchCampaigns();
  };

  const handleEdit = async (i) => {
    settoEdit(i);
    // seteFormLoading(true);
    setshowEditModal(true);
    const response = await getmycampaign(i);
    if(response){
      setCampaign(response[0]);
      seteCampaignName(response[0].name);
      seteStartDate(response[0].startdate);
      seteEndDate(response[0].enddate);
      seteList(response[0].list);
      seteStatus(response[0].status);
    }
  };

  const handleEditCampaign = async (e) => {
    seteFormLoading(true);
    e.preventDefault();
    const response = await updatemycampaign(eCampaignName, eStartDate, eEndDate, eStatus, toEdit);
    if(response == 'success'){
      settoEdit('');
      setshowEditModal(false);
      seteCampaignName('');
      seteStartDate('');
      seteEndDate('');
      seteList('');
      seteStatus('');
      seteFormLoading(false);
      fetchCampaigns();
    }
  };

  const handleDelete = async () => {
    if (!isLoading) {
      try {
        // const result = await Swal.fire({
        //   title: "Are you sure to delete?",
        //   text: "You won't be able to undo this action",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonColor: "#3085d6",
        //   cancelButtonColor: "#d33",
        //   confirmButtonText: "Yes, Delete",
        // });

        // if (result.isConfirmed) {
        //   let response = await deleteinfluencer(todelete);
        //   if (response === "success") {
        //     fetchInfluencers();
        //   }
        // }
        
        let response = await deletemycampaign(toDelete);
        if (response === "success") {
          setCampaigns(campaigns.filter(campaigns => campaigns.campaignid !== toDelete));
          settoDelete('');
        }

      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  useEffect(() => {
    if (newCampaignName !== "" && newStartDate !== "" && newEndDate !== "" && newList !== "") {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  }, [newCampaignName, newStartDate, newEndDate, newList]);

  useEffect(() => {
    if (eCampaignName !== "" && eStartDate !== "" && eEndDate !== "" && eList !== "") {
      setIseFormChanged(true);
    } else {
      setIseFormChanged(false);
    }
  }, [eCampaignName, eStartDate, eEndDate, eList]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      // fetchInfluencers(listId);
    }
  };

  var statusoptions = ['Draft','Active','Completed','Cancelled'];

  return (
    <AppLayout pageId="team" pageName={"Team"}>
      <section>
        <div className="flex justify-between">
            <div className="page-head">
                <h4>Team</h4>
                {teamMembers.length > 0 && teamMembers.length+' team members'}
            </div>
            <div>
                { showNewModal && 
                <Modal heading="New campaign" isOpen={showNewModal} onClose={() => setshowNewModal(false)}>
                <form onSubmit={handleAddNewCampaign}>
                    <div className="modal-body">
                        <div className="space-y-8">
                            <div>
                                <label>Campaign Name</label>
                                <input className="form-control" type="text" maxLength={30} value={newCampaignName} onChange={(e) => setnewCampaignName(e.target.value)} placeholder="Summer campaign" required/>
                            </div>
                            <div className="grid grid-cols-2 gap-10">
                                <div>
                                    <label>Start date</label>
                                    <input className="form-control" type="date" value={newStartDate} onChange={(e) => setnewStartDate(e.target.value)} placeholder="Select Date" required/>
                                </div>
                                <div>
                                    <label>End date</label>
                                    <input className="form-control" type="date" value={newEndDate} onChange={(e) => setnewEndDate(e.target.value)} placeholder="Select Date" required/>
                                </div>
                            </div>
                            <div>
                                <label>Add influencers from lists</label>
                                <div className="relative">
                                    <FiChevronDown className="absolute right-4 top-4"/>
                                    <select className="form-control" onChange={(e) => setnewList(e.target.value)} required>
                                        <option selected disabled>Select</option>
                                        <option value={userToken.userid}>Saved</option>
                                        <option value={userToken.teamid}>Shared with Team</option>
                                        {
                                        lists.map((list, index) => {
                                        return(
                                            <option value={list.listid}>{list.name}</option>
                                        )
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer mt-14 flex justify-end space-x-5">
                    {/* <button type="submit" className="btn-submit">Submit</button> */}
                    { !formLoading && <button type="button" className="btn-cancel" onClick={() => setshowNewModal(false)}> Cancel </button> }
                    <button type="submit" className="btn-submit" disabled={formLoading || !isFormChanged}>
                        {formLoading === false ? "Save campaign" : <Bounce className="my-2" animating={formLoading} />}
                    </button>
                    </div>
                </form>
                </Modal>
                }
                { showEditModal && 
                <Modal heading="Edit campaign" isOpen={showEditModal} onClose={() => setshowEditModal(false)}>
                <form onSubmit={handleEditCampaign}>
                    <div className="modal-body">
                    {campaign != '' ? 
                    <>
                        <div className="space-y-8">
                            <div>
                                <label>Campaign Name</label>
                                <input className="form-control" type="text" maxLength={30} value={eCampaignName} onChange={(e) => seteCampaignName(e.target.value)} placeholder="Summer campaign" required/>
                            </div>
                            <div className="grid grid-cols-2 gap-10">
                                <div>
                                    <label>Start date</label>
                                    <input className="form-control" type="date" value={eStartDate} onChange={(e) => seteStartDate(e.target.value)} placeholder="Select Date" required/>
                                </div>
                                <div>
                                    <label>End date</label>
                                    <input className="form-control" type="date" value={eEndDate} onChange={(e) => seteEndDate(e.target.value)} placeholder="Select Date" required/>
                                </div>
                            </div>
                            <div>
                                <label>Status</label>
                                <div className="relative">
                                    <FiChevronDown className="absolute right-4 top-4"/>
                                    <select className="form-control" value={eStatus} onChange={(e) => seteStatus(e.target.value)} required>
                                        {/* <option selected disabled>Select</option> */}
                                        {
                                        statusoptions.map((option, index) => {
                                        return(
                                            <option value={option}>{option}</option>
                                        )
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </> 
                    : 
                    <div className="flex justify-center w-full py-12">
                      <Spinner color="#CCCCCC" size={24} />
                    </div>
                    }
                    </div>
                    {campaign != '' && <>
                    <div className="modal-footer mt-14 flex justify-end space-x-5">
                    {/* <button type="submit" className="btn-submit">Submit</button> */}
                    { !eformLoading && <button type="button" className="btn-cancel" onClick={() => setshowEditModal(false)}> Cancel </button> }
                    <button type="submit" className="btn-submit" disabled={eformLoading || !iseFormChanged}>
                        {eformLoading === false ? "Save changes" : <Bounce className="my-2" animating={eformLoading} />}
                    </button>
                    </div>
                    </> }
                </form>
                </Modal>
                }
                <button className={`btn-new flex items-center`} onClick={() => setshowNewModal(true)}><PiPlusCircle size={26} className="mr-1.5"/> Invite colleagues</button>
            </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <>
          { teamMembers.length > 0 ? 
          <>
          <div className="table-wrapper flex-1" onScroll={handleScroll}>
            <table className="table w-full">
              <thead className="text-sm text-neutral-400">
                <tr>
                  { !editing && <> 
                    <th scope="col"> Campaign </th>
                    <th scope="col"> Status </th>
                    <th scope="col"> Influencers in campaign </th>
                    <th scope="col"> Start date </th>
                    <th scope="col"> End date </th>
                    <th scope="col"> Edit </th>
                    <th scope="col"> Delete </th>
                  </> }
                </tr>
              </thead>
              <tbody>
                {campaigns.map((campaign, index) => {
                  return (
                    <tr key={index}>
                      { toDelete == campaign.campaignid ? 
                      <td colSpan={'7'}>
                        <div className="flex justify-end items-center space-x-5">
                          <div className="text-sm">Are you sure?</div>
                          <div><button type="button" className="btn-cancel text-black !py-3 !px-6 !bg-[#f4f4ff] !border-0" onClick={() => settoDelete('')}> No </button></div>
                          <div><button type="button" className="btn-delete !py-3 !px-8 !border-0" onClick={() => handleDelete()}> Delete </button></div>
                        </div>
                      </td>
                      : 
                      <>
                      <td className="name">{campaign.name}</td>
                      <td>
                        <div>
                          <div className="relative mx-auto w-40">
                            <button class={`btn-status flex items-center justify-between space-x-3 ${showStatusDropdown == campaign.campaignid && '!rounded-b-none'}`} onClick={() => setShowStatusDropdown(showStatusDropdown == campaign.campaignid ? '' : campaign.campaignid)}>
                              <GoDotFill size={12} className={`${campaign.status === 'Active' ? 'text-green-500' : campaign.status === 'Draft' ? 'text-neutral-400' : campaign.status === 'Completed' ? 'text-purple-600' : 'text-orange-600'}`} /> <span>{campaign.status}</span> <HiMiniChevronDown size={16}/>
                            </button>
                            { showStatusDropdown == campaign.campaignid && 
                            <div className="status-dropdown">
                              {
                              statusoptions.map((option, index) => {
                                return(
                                  <div className={`dropdown-item`}>{option}</div>
                                )
                              })
                              }
                            </div> 
                            }
                          </div>
                        </div>
                      </td>
                      <td>{campaign.totalinfluencers}</td>
                      <td className="text-sm">{campaign.startdate}</td>
                      <td className="text-sm">{campaign.enddate}</td>
                      <td><FiEdit3 className="mx-auto hover:text-blue-500 cursor-pointer" onClick={() => handleEdit(campaign.campaignid)} size={20}/></td>
                      <td><TfiTrash className="ml-auto mr-3 hover:text-red-500 cursor-pointer" onClick={() => settoDelete(campaign.campaignid)} size={20}/></td>
                      </> }
                    </tr>
                  )
                })}
                {loadingMore && (
                  <tr>
                    <td colSpan="5" className="!text-center !py-10">
                      <Spinner color="#CCCCCC" size={24} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          </>
          :
          <div className="text-center space-y-8 mt-20">
            <img className="mx-auto max-w-8/12" src="/images/no-team.png"/>
            <div className="text-black space-y-2">
              <h4 className="text-xl font-semibold">Work together with your team, share lists and chat</h4>
              <h5 className="text-base">Send an invite to make it easy for them to join</h5>
            </div>
            <div>
              <button className={`btn-new flex items-center mx-auto`} onClick={() => setshowNewModal(true)}><PiPlusCircle size={26} className="mr-1.5"/> Invite colleagues</button>
            </div>
          </div>
          }
          </>
        )}
      </section>
    </AppLayout>
  );
};

export default Team;
