import STRIPE_PUBLIC_KEY from "../config";
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import BASE_URL from "../config";
import AppLayout from '../layouts/AppLayout';
import { HiCheck } from 'react-icons/hi';
import Spinner from 'react-activity/dist/Spinner';
import "react-activity/dist/Spinner.css";
import { PiStarFour } from "react-icons/pi";
import { AppContext } from '../contexts/AppContext';
import { loadStripe } from '@stripe/stripe-js'; 

const Plans = () => {
  const userToken = JSON.parse(localStorage.getItem('userToken'));

  const stripePromise = loadStripe("pk_test_0erQNzYrhNahOtVUMB5L3kKV00Fbp33J0X");

  const { subscribePlan, isLoading } = useContext(AppContext);
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoadingPlan, setIsPageLoadingPlan] = useState(false);

  useEffect(() => {
    console.log("Stripe Public Key:", process.env.REACT_APP_STRIPE_PUBLIC_KEY);


    const fetchPlans = async () => {
      setIsPageLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/plans/all`);
        setPlans(response.data.plans);
        setIsPageLoading(false);
      } catch (error) {
        console.error('Error fetching plans', error);
        setIsPageLoading(false);
      }
    };

    const fetchCurrentPlan = async () => {
      setIsPageLoadingPlan(true);
      const response = await axios.post(`${BASE_URL}/user/getCurrentPlan`, {
        userId: userToken.userid,
      });
      setCurrentPlan(response.data.plan.name);
      setIsPageLoadingPlan(false);
    };

    fetchPlans();
    fetchCurrentPlan();
  }, [userToken.userid]);

  const handleUpgrade = async (planId) => {
    if (currentPlan === planId) return;

    try {
        const response = await subscribePlan(planId);
        console.log(response);
        if (response.sessionId) {
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
          stripe.redirectToCheckout({ sessionId: response.sessionId });
        } else if (response.message === 'success' && response.plan === 'Free') {
            setCurrentPlan(planId);
        }
    } catch (error) {
        console.error('Error upgrading plan:', error);
    }
  };

  return (
    <AppLayout pageId="plans" pageName={"Plans & Billing"}>
      {isLoading ? (
        <div className="flex justify-center py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      ) : (
        <section>
          <div className="grid grid-cols-3 gap-5">
            {plans.map((plan) => (
              <PlanCard 
                key={plan.id}
                plan={plan}
                currentPlan={currentPlan === plan.name}
                onClick={() => handleUpgrade(plan.id)}
              />
            ))}
          </div>
        </section>
      )}
    </AppLayout>
  );
};

const PlanCard = ({ plan, currentPlan, onClick }) => {
  const features = plan.features.split(';');

  return (
    <div className={`border shadow-lg rounded-lg flex flex-col justify-between ${currentPlan ? 'bg-gray-50' : ''}`}>
      <div>
        <div className={`px-8 pt-12 pb-8 ${plan.name == 'Free' ? 'border-b border-b-gray-100' : 'bg-main text-white rounded-t-lg'}`}>
          <h4 className="text-3xl font-bold mb-5">{plan.name}</h4>
          <h4 className="text-4xl font-bold">${plan.price} <span className="text-sm font-normal">USD</span></h4>
          <div className="text-base mt-1">per person/month</div>
        </div>
        <div className="px-8 py-8">
          <div className="text-lg font-semibold mb-3">Plan highlights:</div>
          <div className="text-[14px] font-medium space-y-2">
            { plan.name == 'Business' && 
            <div className="flex items-center text-accent space-x-3">
                <PiStarFour className="text-accent"/> <span className="text-accent border-b-2 border-b-green-950 border-dotted">Poppiq AI influencer finder</span>
            </div>
            }
            {features.map((feature, index) => (
              <div key={index} className="flex items-center space-x-3">
                <HiCheck className="text-green-600" /> <span>{feature.trim()}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="px-8 pb-12">
        <button type="button" className={`btn btn-submit-inv ${currentPlan ? "opacity-70" : "bg-main-imp text-white"} !text-base !py-3 !font-semibold mt-5 w-full`} onClick={onClick} disabled={currentPlan}>
          {currentPlan ? "Current Plan" : "Choose Plan"}
        </button>
      </div>
    </div>
  );
};

export default Plans;
