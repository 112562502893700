import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const { getdashboard, userToken, isLoading } = useContext(AppContext);
  const [stats, setStats] = useState([]);

  const fetchDashboard = async () => {
    const response = await getdashboard();
    setStats(response);
  };

  const StatBox = ({ value, label, info, link }) => (
    <Link to={link}>
      <section className=" !shadow-[#FFEFD4] p-6 rounded-lg bg-[#FFEFD4]">
        <h6 className="text-lg text-neutral-600 font-semibold" dangerouslySetInnerHTML={{ __html: label }}></h6>
        {info && <span className="px-2 py-1 bg-neutral-200 text-neutral-500 rounded-sm text-xs font-medium leading-none">&bull;&nbsp; {info}</span>}
        <h2 className="text-7xl text-center font-bold text-[#444] my-4">{value}</h2>
      </section>
    </Link>
  );

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <AppLayout pageName={"Dashboard"}>
      {isLoading ? 
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div> : (
        <>
          {userToken.type == "Admin" && (
            <>
              <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 max-sm:mt-1 gap-3">
                <StatBox link="/all-customers" value={stats.customers} label="Total Customers" />
                <StatBox link="/all-influencers" value={stats.influencers} label="Total Influencers" />
              </div>
            </>
          )}

          {userToken.type == "Influencer" && (
            <>
              {/* <div className="mb-4">
                <section className="!bg-blue-50 text-main !border-blue-200 shadow-lg shadow-blue-200">
                  <h6 className="text-sm font-medium text-neutral-600 mb-1">One last step..</h6>
                  <h2 className="text-4xl font-bold mb-2">
                    <u>Connect Your TikTok Account</u>
                  </h2>
                  <h6 className="text-base text-neutral-600 font-medium leading-loose mb-4">
                    To complete the onboarding, please connect your TikTok account. It's a simple process.
                    <br />
                    We ensure your data is handled with care—your login details will not be stored.
                    <br />
                    Press 'Continue' and authorize access to start your journey with us.
                  </h6>
                  <button class="btn btn-main !py-4 !px-10 !border-neutral-900 hover:!text-white !bg-neutral-900 flex items-center space-x-2 hover:space-x-3 hover:!shadow-2xl shadow-neutral-900">
                    <span>Continue</span> <FaArrowRight className="transition-all" />
                  </button>
                  <hr className="mt-8 mb-6 border-blue-200" />
                  <h6 className="text-sm text-neutral-500 leading-loose font-medium">
                    Need assistance? Our support team is just a message away.
                    <br />
                    Please Note: This step is necessary to complete the onboarding process and get hired for the campaigns.
                  </h6>
                </section>
              </div> */}
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 max-sm:mt-1 gap-3">
                <StatBox link="/assigned-campaigns" value={stats.totalcampaignoffers} label="Total Campaign Offers" />
                <StatBox link="/assigned-campaigns" value={stats.awaitingapproval} label="Awaiting Approval" info="New Assigned" />
                <StatBox link="/assigned-campaigns" value={stats.workinprogress} label="Work in Progress" info="Awaiting Video Submission" />
                <StatBox link="/assigned-campaigns" value={stats.reviewingvideo} label="Reviewing Video" info="Video Under Review" />
                <StatBox link="/assigned-campaigns" value={stats.resubmitvideo} label="Re-Submit Video" info="Submission Rejected" />
                <StatBox link="/assigned-campaigns" value={stats.approved} label="Awaiting Payment" info="Video Approved" />
                <StatBox link="/assigned-campaigns" value={stats.completed} label="Completed" info="Successful" />
              </div>
            </>
          )}

          {userToken.type == "Business" && (
            <>
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 max-sm:mt-1 gap-3">
                <StatBox link="/my-campaigns" value={stats.totalcampaigns} label="Total Campaigns" />
                <StatBox link="/my-campaigns" value={stats.underreview} label="Under Review" info="New Submissions" />
                <StatBox link="/my-campaigns" value={stats.active} label="Active" info="In Progress" />
                <StatBox link="/my-campaigns" value={stats.completed} label="Completed" info="Successful" />
              </div>
            </>
          )}

          {userToken.type == "Customer" && (
            <>
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 max-sm:mt-1 gap-3">
                {/* <StatBox link="/my-campaigns" value={stats.totalcampaigns} label="Total Campaigns" />
                <StatBox link="/my-campaigns" value={stats.underreview} label="Under Review" info="New Submissions" />
                <StatBox link="/my-campaigns" value={stats.active} label="Active" info="In Progress" />
                <StatBox link="/my-campaigns" value={stats.completed} label="Completed" info="Successful" /> */}
              </div>
            </>
          )}
        </>
      )
      }
    </AppLayout>
  );
};

export default Dashboard;
