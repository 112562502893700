import "./App.css";
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import AddCustomer from "./pages/AddCustomer";
import AllCustomers from "./pages/AllCustomers";
import MyCampaigns from "./pages/MyCampaigns";
import MyCampaignDetails from "./pages/MyCampaignDetails";
import NewCampaign from "./pages/NewCampaign";
import AddCampaign from "./pages/AddCampaign";
import AllCampaigns from "./pages/AllCampaigns";
import AddInfluencer from "./pages/AddInfluencer";
import AllInfluencers from "./pages/AllInfluencers";
import CompaignDetails from "./pages/CampaignDetails";
import MyAssignedCampaigns from "./pages/MyAssignedCampaigns";
import MyAssignedCampaign from "./pages/MyAssignedCampaign";
import MyProfile from "./pages/MyProfile";
import Earnings from "./pages/Earnings";
import AllPayments from "./pages/AllPayments";
import PrivateRoute from "./components/PrivateRoute";
import ChangePassword from "./pages/ChangePassword";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import InfluencerAnalytics from "./pages/InfluencerAnalytics";
import './styles/fonts.scss';
import Discover from "./pages/Discover";
import GoogleAuthSuccess from "./components/GoogleAuthSuccess";
import Lists from "./pages/Lists";
import Onboarding from "./pages/Onboarding";
import Campaigns from "./pages/Campaigns";
import Monitor from "./pages/Monitor";
import Team from "./pages/Team";
import Analytics from "./pages/Analytics";
import Settings from "./pages/Settings";
import Plans from "./pages/Plans";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/register" element={<Register />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/google-success" element={<GoogleAuthSuccess />} />

        <Route path="/dashboard" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
        <Route path="/discover" element={ <PrivateRoute> <Discover /> </PrivateRoute> } />
        <Route path="/lists" element={ <PrivateRoute> <Lists /> </PrivateRoute> } />
        <Route path="/campaigns" element={<PrivateRoute><Campaigns /></PrivateRoute>} />
        <Route path="/campaigns/:campaignId" element={<PrivateRoute><CompaignDetails /></PrivateRoute>} />
        <Route path="/monitor" element={<PrivateRoute><Monitor /></PrivateRoute>} />
        <Route path="/analytics" element={<PrivateRoute><Analytics /></PrivateRoute>} />
        <Route path="/team" element={<PrivateRoute><Team /></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
        <Route path="/plans" element={<PrivateRoute><Plans /></PrivateRoute>} />

        {/* <Route path="/" element={ <PrivateRoute> <Discover /> </PrivateRoute> } /> */}
        <Route path="/profile" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
        <Route path="/password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
        <Route path="/add-customer" element={<PrivateRoute allowedto={["Admin"]}><AddCustomer /></PrivateRoute>} />
        <Route path="/add-customer/:userId" element={<PrivateRoute allowedto={["Admin"]}><AddCustomer /></PrivateRoute>} />
        <Route path="/all-customers" element={<PrivateRoute allowedto={["Admin"]}><AllCustomers /></PrivateRoute>} />
        <Route path="/add-influencer" element={<PrivateRoute allowedto={["Admin"]}><AddInfluencer /></PrivateRoute>} />
        <Route path="/add-influencer/:influencerId" element={<PrivateRoute allowedto={["Admin"]}><AddInfluencer /></PrivateRoute>} />
        <Route path="/all-influencers" element={<PrivateRoute allowedto={["Admin"]}><AllInfluencers /></PrivateRoute>} />
        <Route path="/all-payments" element={<PrivateRoute allowedto={["Admin"]}><AllPayments /></PrivateRoute>} />
        <Route path="/influencer-analytics" element={<PrivateRoute allowedto={["Business"]}><InfluencerAnalytics /></PrivateRoute>} />
        <Route path="/my-campaigns" element={<PrivateRoute allowedto={["Business"]}><MyCampaigns /></PrivateRoute>} />
        <Route path="/my-campaigns/:campaignId" element={<PrivateRoute allowedto={["Business"]}><MyCampaignDetails /></PrivateRoute>} />
        <Route path="/new-campaign" element={<PrivateRoute allowedto={["Business"]}><NewCampaign /></PrivateRoute>} />
        <Route path="/new-campaign/:campaignId" element={<PrivateRoute allowedto={["Business"]}><NewCampaign /></PrivateRoute>} />
        <Route path="/add-campaign" element={<PrivateRoute allowedto={["Admin"]}><AddCampaign /></PrivateRoute>} />
        <Route path="/add-campaign/:campaignId" element={<PrivateRoute allowedto={["Admin"]}><AddCampaign /></PrivateRoute>} />
        <Route path="/all-campaigns" element={<PrivateRoute allowedto={["Admin"]}><AllCampaigns /></PrivateRoute>} />
        <Route path="/campaign-details/:campaignId" element={<PrivateRoute allowedto={["Admin"]}><CompaignDetails /></PrivateRoute>} />
        <Route path="/assigned-campaigns" element={<PrivateRoute allowedto={["Influencer"]}><MyAssignedCampaigns /></PrivateRoute>} />
        <Route path="/assigned-campaigns/:campaignId" element={<PrivateRoute allowedto={["Influencer"]}><MyAssignedCampaign /></PrivateRoute>} />
        <Route path="/earnings" element={<PrivateRoute allowedto={["Influencer"]}><Earnings /></PrivateRoute>} />

      </Routes>
    </Router>
  );
}

export default App;
