import AppLayout from "../layouts/AppLayout";
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { VscSend } from "react-icons/vsc";
import { FaLink } from "react-icons/fa6";

import "../styles/chat.css";
import ScrollToBottom from "react-scroll-to-bottom";
import Swal from "sweetalert2";
import ChatBox from "../components/ChatBox";

const MyCompaignDetails = () => {
  const { campaignId } = useParams();

  const navigate = useNavigate();

  const [campaignvideos, setCampaignVideos] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const [video, setVideo] = useState("");
  const [showCounterForm, setShowCounterForm] = useState(false);
  const [cos, setcos] = useState(false);
  const [accepted, setaccepted] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [formloader, setformloader] = useState(false);
  const [informerror, setinformerror] = useState("");
  const [assignedinfluencers, setAssignedinfluencers] = useState([]);
  const [assignedinf, setAssignedinf] = useState([]);
  const [inftoreview, setinftoreview] = useState("");
  const [newinfluencers, setnewinfluencers] = useState([]);
  const [newinfluencer, setnewinfluencer] = useState("");
  const [newbudget, setnewbudget] = useState("");
  const [newdeadline, setnewdeadline] = useState("");
  const [newnote, setnewnote] = useState("");
  const { socket, getcampaigninf, fetchUnreadMessages, getmycampaign, getmycampaignvideos, approveinf, rejectinf, addcounteroffer, acceptoffer, submitvideo, isLoading } = useContext(AppContext);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [videolink, setvideolink] = useState("");

  const fetchCampaign = async () => {
    const response = await getmycampaign(campaignId);
    if (response) {
      setCampaign(response["campaign"][0]);
      setCampaignVideos(response["video"].length > 0 ? response["video"] : []);
    } else {
      // navigate('/my-campaigns')
    }
  };

  const fetchCampaignVideos = async () => {
    const response = await getmycampaignvideos(campaignId);
    setCampaignVideos(response);
  };

  const fetchAssignedinf = async () => {
    const response = await getcampaigninf(campaignId);
    if (response) {
      setAssignedinf(response.influencers);
      setinftoreview(response.inftoreview);
    }
  };

  useEffect(() => {
    if (campaignId) {
      fetchCampaign();
      fetchAssignedinf();
    }
  }, [campaignId]);

  useEffect(() => {
    if (socket && campaignId) {
      function getConversationId(campaignId, userId1, userId2) {
        return [campaignId, userId1, userId2].sort().join("-");
      }

      const convid = getConversationId(campaignId, userToken.userid, "");

      socket.emit("join conversation", { sender: userToken.userid, receiver: "", campaignId: campaignId });

      // Fetch old chat messages
      socket.emit("fetch messages", {
        sender: userToken.userid,
        usertype: userToken.type,
        receiver: "",
        campaignid: campaignId,
      });

      // Receive old chat messages
      socket.on("old messages", (data) => {
        setMessages(data);
        fetchUnreadMessages();
      });

      // Receive new chat message
      socket.on("chat message", (data) => {
        const { sender, receiver, message } = data;

        // Check if the received message is for the selected conversation
        setMessages((prevMessages) => [...prevMessages, data]);
      });

      return () => {
        socket.off("chat message");
        socket.off("old messages");
        socket.emit("leave conversation", userToken.userid);
      };
    }
  }, [socket]);

  const sendMessage = () => {
    const receiver = "";
    socket.emit("chat message", {
      title: campaign.title,
      campaignId,
      sender: userToken.userid,
      receiver,
      message: messageText.trim(),
    });
    setMessageText("");
  };

  const handleApproveInf = async (inf) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to Approve?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Approve!",
        });

        if (result.isConfirmed) {
          let response = await approveinf(inf, campaignId);
          if (response === "success") {
            fetchAssignedinf();
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const handleRejectInf = async (inf) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to Reject?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Reject!",
        });

        if (result.isConfirmed) {
          let response = await rejectinf(inf, campaignId);
          if (response === "success") {
            fetchAssignedinf();
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  return (
    <AppLayout pageName="Campaign Details">
      {campaign ? (
        <>
          {campaign.status == "In Progress" && (
            <div id="alert-additional-content-1" className="p-5 mb-2 text-main border border-blue-100 rounded-lg bg-blue-50" role="alert">
              <div className="flex items-center">
                <h3 className="text-xl font-semibold">Your campaign is under works</h3>
              </div>
              <div className="mt-2 text-base text-medium">We're awaiting video submissions from our assigned influencers. They will be available to view as soon as it gets approved from us. Thank you!</div>
            </div>
          )}

          

          {inftoreview > 0 && (
            <div id="alert-additional-content-1" className="p-5 mb-2 text-yellow-500 border border-yellow-300 rounded-lg bg-yellow-50" role="alert">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-medium">Please review the Influencers list to move forward</h3>
              </div>
            </div>
          )}

          <section>
            <div className="flex flex-row justify-between">
              <div>
                <h6 className="text-sm font-medium text-neutral-500 mb-0 leading-none">TITLE</h6>
                <h2 className="text-3xl font-semibold tracking-normal">{campaign.title}</h2>
              </div>
              <div className="text-right">
                <h6 className="text-sm font-medium text-neutral-500 mb-0 leading-none">STATUS</h6>
                <h2 className="text-3xl font-semibold tracking-normal">{campaign.status}</h2>
              </div>
            </div>
            <table className="tbl-details mt-3">
              <tbody>
                <tr>
                  <th> Title: </th>
                  <td> {campaign.title} </td>
                </tr>
                <tr>
                  <th> Budget: </th>
                  <td> ${campaign.budget.toLocaleString("en-US")} </td>
                </tr>
                <tr>
                  <th> Expected Reach: </th>
                  <td> {campaign.reach.toLocaleString("en-US")} </td>
                </tr>
                <tr>
                  <th> Status: </th>
                  <td> {campaign.status} </td>
                </tr>
                <tr>
                  <th> Added At: </th>
                  <td> {campaign.addedatdate} </td>
                </tr>
              </tbody>
            </table>
          </section>
          {campaign.infreview == 0 && (
          <>
          <section>
            <div className="flex flex-row justify-between">
              <div>
                <h2 className="text-2xl font-semibold text-main tracking-normal">Campaign Videos</h2>
              </div>
            </div>
            <div>
              {campaignvideos.length == 0 ? (
                <div className="w-full p-10 text-center text-neutral-400 text-sm">None submitted yet</div>
              ) : (
                <ul role="list" className="divide-y divide-neutral-200 border-t border-neutral-200 mt-4">
                  {campaignvideos.map((campaignvideo) => (
                    <li key={campaignvideo.user} className="py-5 space-y-5">
                      <section className={`space-y-5 opacity-90 hover:opacity-100 !mb-0`}>
                        <div className="block md:flex flex-row md:space-x-5">
                          <div className="flex flex-1">
                            <a className="w-full" target={"_blank"} href={campaignvideo.video}>
                              <div className="p-3 flex flex-1 items-start md:items-center px-4 border border-neutral-200 rounded-lg w-full overflow-hidden">
                                <FaLink className="mr-3 hidden md:block" color="#333" size={20} /> <span className="break-all">{campaignvideo.video}</span>
                              </div>
                            </a>
                          </div>
                          <a target={"_blank"} href={campaignvideo.video} className="btn-submit-alt !hidden md:!flex">
                            Visit Link
                          </a>
                        </div>
                        <div className="flex flex-row space-x-5">
                          <div className="w-1/5">
                            <img className="w-full rounded-lg" src={campaignvideo.image} />
                          </div>
                          <div className="w-4/5">
                            <div className="flex flex-1 h-full flex-col justify-between">
                              <div>
                                <h2 className="text-xl mb-5 font-semibold text-neutral-900 tracking-normal">Video Overview</h2>
                                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                  <div>
                                    <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Views</h6>
                                    <h2 className="text-lg font-semibold tracking-normal">{campaignvideo.views}</h2>
                                  </div>
                                  <div>
                                    <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Likes</h6>
                                    <h2 className="text-lg font-semibold tracking-normal">{campaignvideo.likes}</h2>
                                  </div>
                                  <div>
                                    <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Shares</h6>
                                    <h2 className="text-lg font-semibold tracking-normal">{campaignvideo.shares}</h2>
                                  </div>
                                  <div>
                                    <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Comments</h6>
                                    <h2 className="text-lg font-semibold tracking-normal">{campaignvideo.comments}</h2>
                                  </div>
                                </div>
                                <div className="mt-5">
                                  <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Caption</h6>
                                  <h2 className="text-base font-medium tracking-normal">{campaignvideo.caption}</h2>
                                </div>
                              </div>
                              <div className="flex flex-col md:flex-row justify-between pt-5 border-t-0 md:border-t border-neutral-200">
                                <div className="flex min-w-0 gap-x-2">
                                  <img className="h-10 w-10 flex-none rounded-full bg-gray-50" src={`data:image/png;base64,${campaignvideo.profileimage}`} alt="" />
                                  <div className="leading-tight">
                                    <p className="text-sm tracking-wide font-semibold text-gray-900">{campaignvideo.influencername}</p>
                                    <div className="text-xs tracking-wide text-gray-500 space-y-0.5 font-normal">
                                      <div className="flex items-center">
                                        <MdOutlineAlternateEmail className="-ml-0.5 w-4" /> {campaignvideo.influencerusername}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-row md:flex-col space-x-1 md:space-x-0 text-neutral-400 md:text-right text-xs pt-3 md:pt-1">
                                  <div>Analytics Last Updated:</div>
                                  <div className="font-medium">{campaignvideo.analyticsupdated}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>
          </>
          )}
          {campaign.infreview == 1 && (
          <>
          <section>
            <div className="flex flex-row justify-between">
              <div>
                <h2 className="text-2xl font-semibold text-main tracking-normal">Influencers List to Review</h2>
              </div>
            </div>
            <div>
              {assignedinf.length == 0 ? (
                <div className="w-full p-10 text-center text-neutral-400 text-sm">None assigned yet</div>
              ) : (
                <ul role="list" className="divide-y divide-neutral-200 border-t border-neutral-200 mt-4">
                  {assignedinf.map((influencer) => (
                    <li key={influencer.user} className="py-5 space-y-5">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-row-reverse md:flex-row w-full md:min-w-0 gap-x-0 md:gap-x-4 justify-between md:justify-start">
                          <img className="h-12 w-12 flex-none rounded-full bg-gray-50 mt-1" src={"https://assets-global.website-files.com/6526b48657e93d44ab506c3b/6532b85b02390add49392fdf_profile-image.png"} alt="" />
                          <div className="">
                            <p className="text-lg tracking-wide font-semibold text-gray-900">{influencer.influencername}</p>
                            <div className="text-sm tracking-wide text-gray-500 space-y-0.5 font-medium">
                              <div className="flex items-center">
                                <MdOutlineAlternateEmail className="w-4 mr-0.5 mt-0.5" /> {influencer.influencerusername}
                              </div>
                              <div className="flex items-center">
                                <IoLocationOutline className="w-4 mr-0.5 mt-0.5" /> {influencer.influenceraddress}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <p className="text-lg leading-6 font-semibold text-gray-900">{influencer.status}</p>
                          <div className="text-sm tracking-wide text-gray-500 space-y-0.5 font-medium">
                            <div className="flex text-[13px] pt-1 items-center font-normal">{influencer.description}</div>
                          </div>
                          {campaign.infreview != 0 && (
                            <>
                            { influencer.status == "Under Review" && 
                            <div className="flex mt-3 space-x-3">
                              <button onClick={() => handleApproveInf(influencer.user)} className={`btn btn-new !font-semibold`}> Approve </button>
                              <button onClick={() => handleRejectInf(influencer.user)} className={`btn btn-new-red !font-semibold`}> Reject </button>
                            </div>
                            }
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>
          </>
          )}

          <section>
            <h2 className="text-2xl font-semibold text-main tracking-normal mb-1">Ask Questions</h2>
            <ScrollToBottom className="flex flex-1 w-full overflow-y-hidden mb-3 chatbox bg-[#FFF] h-96 border border-neutral-200 rounded-lg">
              <ChatBox messages={messages} />
            </ScrollToBottom>
            <div>
              <div className="flex w-full border border-main rounded-lg mb-4">
                <input
                  type="text"
                  className="flex-1 p-3 bg-transparent rounded-lg mr-2 text-neutral-800 hover:outline-none focus:outline-none"
                  placeholder="Type your message"
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  onKeyPress={(event) => {
                    event.key === "Enter" && sendMessage();
                  }}
                />
                <button type="button" className="bg-main text-white rounded-r-md p-3 px-4" onClick={sendMessage}>
                  <VscSend color="#FFF" size={24} />
                </button>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default MyCompaignDetails;
