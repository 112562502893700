import AppLayout from "../layouts/AppLayout";
import React, { Fragment, useEffect, useState, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";

const AddInfluencer = () => {
  const { influencerId } = useParams();

  const [influencer, setInfluencer] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [instagramUsername, setinstagramUsername] = useState("");
  const [instagramPosts, setinstagramPosts] = useState("");
  const [instagramFollowers, setinstagramFollowers] = useState("");
  const [instagramFollowing, setinstagramFollowing] = useState("");
  const [tiktokUsername, settiktokUsername] = useState("");
  const [tiktokLikes, settiktokLikes] = useState("");
  const [tiktokFollowers, settiktokFollowers] = useState("");
  const [tiktokFollowing, settiktokFollowing] = useState("");
  const [youtubeUsername, setyoutubeUsername] = useState("");
  const [youtubeVideos, setyoutubeVideos] = useState("");
  const [youtubeViews, setyoutubeViews] = useState("");
  const [youtubeSubscribers, setyoutubeSubscribers] = useState("");
  const [twitchUsername, settwitchUsername] = useState("");
  const [twitchFollowers, settwitchFollowers] = useState("");
  const [twitchAvgViews, settwitchAvgViews] = useState("");
  const [twitchSubscribers, settwitchSubscribers] = useState("");
  const [status, setStatus] = useState("");
  const [image, setImage] = useState(null); // NEW: State for the image
  const [imagePreview, setImagePreview] = useState(null); // NEW: State for image preview
  const [existingImage, setExistingImage] = useState(null); // NEW: Existing image from DB
  const [error, setError] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [reviewaction, setReviewaction] = useState("");

  const navigate = useNavigate();

  const { getinfluencer, addinfluencer, updateinfluencer, isLoading } = useContext(AppContext);

  useEffect(() => {
    const fetchInfluencer = async () => {
      const response = await getinfluencer(influencerId);
      // Update the state with the received products
      if (response) {
        console.log(response[0].profileimage);
        setInfluencer(response[0]);
        setName(response[0].name);
        setLocation(response[0].location);
        setCategory(response[0].category);
        setinstagramUsername(response[0].instagram);
        setinstagramPosts(response[0].instagramposts);
        setinstagramFollowers(response[0].instagramfollowers);
        setinstagramFollowing(response[0].instagramfollowing);
        settiktokUsername(response[0].tiktok);
        settiktokLikes(response[0].tiktoklikes);
        settiktokFollowers(response[0].tiktokfollowers);
        settiktokFollowing(response[0].tiktokfollowing);
        setyoutubeUsername(response[0].youtube);
        setyoutubeVideos(response[0].youtubevideos);
        setyoutubeViews(response[0].youtubeviews);
        setyoutubeSubscribers(response[0].youtubesubscribers);
        settwitchUsername(response[0].twitch);
        settwitchFollowers(response[0].twitchfollowers);
        settwitchAvgViews(response[0].twitchavgviews);
        settwitchSubscribers(response[0].twitchsubscribers);
        setStatus(response[0].status);
        setExistingImage(`data:image/png;base64,${response[0].profileimage}`); // Fetch existing image path
      }
    };

    if (influencerId) {
      fetchInfluencer();
    }
  }, [influencerId]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result); // Preview the new image
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (name !== "") {
          setError("");
          let response = "";
          if (influencerId) {
            response = await updateinfluencer({ name, category, location, instagramUsername, instagramPosts, instagramFollowers, instagramFollowing, tiktokUsername, tiktokLikes, tiktokFollowers, tiktokFollowing, youtubeUsername, youtubeVideos, youtubeViews, youtubeSubscribers, twitchUsername, twitchFollowers, twitchAvgViews, twitchSubscribers, image, influencerId });
          } else {
            response = await addinfluencer({ name, category, location, instagramUsername, instagramPosts, instagramFollowers, instagramFollowing, tiktokUsername, tiktokLikes, tiktokFollowers, tiktokFollowing, youtubeUsername, youtubeVideos, youtubeViews, youtubeSubscribers, twitchUsername, twitchFollowers, twitchAvgViews, twitchSubscribers, image});
          }
          if (response === "success") {
            navigate("/all-influencers");
          }
        } else {
          setError("Name is required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  return (
    <AppLayout pageName={`${influencerId ? 'Update' : 'Add New'} Influencer`}>
      <section>
        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`flex flex-row flex-wrap w-full ${status == "Under Review" ? "pointer-events-none" : ""}`}>
          <div className="w-2/4 p-2">
            <label htmlFor="name">Influencer Name <span className="text-red-500">*</span></label>
            <input id="name" name="name" required type="text" className="form-control" maxLength={50} value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="w-1/4 p-2">
            <label htmlFor="location">Location</label>
            <input id="location" name="location" type="text" className="form-control" placeholder="Country, State" value={location} onChange={(e) => setLocation(e.target.value)} maxLength={255} />
          </div>
          <div className="w-1/4 p-2">
            <label htmlFor="name">Category</label>
            <input id="category" name="category" type="text" className="form-control" value={category} onChange={(e) => setCategory(e.target.value)} />
          </div>
          <div className="w-full">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Instagram</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="instagramUsername">Username <span className="text-red-500">*</span></label>
                <input id="instagramUsername" name="instagramUsername" type="text" className="form-control" value={instagramUsername} onChange={(e) => setinstagramUsername(e.target.value)} required/>
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="instagramPosts">Posts</label>
                <input id="instagramPosts" name="instagramPosts" type="text" className="form-control" value={instagramPosts} onChange={(e) => setinstagramPosts(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="instagramFollowers">Followers</label>
                <input id="instagramFollowers" name="instagramFollowers" type="text" className="form-control" value={instagramFollowers} onChange={(e) => setinstagramFollowers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="instagramFollowing">Following</label>
                <input id="instagramFollowing" name="instagramFollowing" type="text" className="form-control" value={instagramFollowing} onChange={(e) => setinstagramFollowing(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-full">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Tiktok</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokUsername">Username</label>
                <input id="tiktokUsername" name="tiktokUsername" type="text" className="form-control" value={tiktokUsername} onChange={(e) => settiktokUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokLikes">Likes</label>
                <input id="tiktokLikes" name="tiktokLikes" type="text" className="form-control" value={tiktokLikes} onChange={(e) => settiktokLikes(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="tiktokFollowers">Followers</label>
                <input id="tiktokFollowers" name="tiktokFollowers" type="text" className="form-control" value={tiktokFollowers} onChange={(e) => settiktokFollowers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="tiktokFollowing">Following</label>
                <input id="tiktokFollowing" name="tiktokFollowing" type="text" className="form-control" value={tiktokFollowing} onChange={(e) => settiktokFollowing(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-full">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Youtube</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeUsername">Username</label>
                <input id="youtubeUsername" name="youtubeUsername" type="text" className="form-control" value={youtubeUsername} onChange={(e) => setyoutubeUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeVideos">Videos</label>
                <input id="youtubeVideos" name="youtubeVideos" type="text" className="form-control" value={youtubeVideos} onChange={(e) => setyoutubeVideos(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="youtubeViews">Views</label>
                <input id="youtubeViews" name="youtubeViews" type="text" className="form-control" value={youtubeViews} onChange={(e) => setyoutubeViews(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="youtubeSubscribers">Subscribers</label>
                <input id="youtubeSubscribers" name="youtubeSubscribers" type="text" className="form-control" value={youtubeSubscribers} onChange={(e) => setyoutubeSubscribers(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-full">
            <h6 className="text-base text-main uppercase font-semibold mt-3 -mb-2">Twitch</h6>
            <div className="flex flex-row flex-wrap w-full">
              <div className="w-1/4 p-2">
              <label htmlFor="twitchUsername">Username</label>
                <input id="twitchUsername" name="twitchUsername" type="text" className="form-control" value={twitchUsername} onChange={(e) => settwitchUsername(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="twitchAvgViews">Avg. Views</label>
                <input id="twitchAvgViews" name="twitchAvgViews" type="text" className="form-control" value={twitchAvgViews} onChange={(e) => settwitchAvgViews(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
                <label htmlFor="twitchSubscribers">Subscribers</label>
                <input id="twitchSubscribers" name="twitchSubscribers" type="text" className="form-control" value={twitchSubscribers} onChange={(e) => settwitchSubscribers(e.target.value)} />
              </div>
              <div className="w-1/4 p-2">
              <label htmlFor="twitchFollowers">Followers</label>
                <input id="twitchFollowers" name="twitchFollowers" type="text" className="form-control" value={twitchFollowers} onChange={(e) => settwitchFollowers(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="w-2/4 p-2">
            <label htmlFor="image">Profile Image</label>
            <div className="border border-[#c9c9c9] p-2 rounded-lg">
              <input type="file" accept="image/*" id="image" name="image" className="w-full pointer !rounded-none" onChange={handleImageChange} />
            </div>
            {imagePreview && (
              <div className="mt-4">
                <label>Preview:</label>
                <img src={imagePreview} alt="Selected" style={{ width: "150px", height: "150px" }} />
              </div>
            )}
            {!imagePreview && existingImage && (
              <div className="mt-4">
                <label>Current Image:</label>
                <img src={existingImage} alt="Current" style={{ width: "150px", height: "150px" }} />
              </div>
            )}
          </div>
          <div className="px-2 w-full">
            {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
              <div className="flex space-x-3">
                <button type="submit" className="btn-submit" disabled={isLoading}>
                  <span>{influencerId ? 'Save Changes' : 'Submit'}</span>
                  <span>{isLoading === false ? <HiArrowNarrowRight className="ml-3"/> : <Bounce className="ml-3" animating={isLoading} />}</span>
                </button>
                { influencerId && 
                  <Link to={'/all-influencers'}>
                    <button type="button" className="btn-cancel" disabled={isLoading}>
                      Cancel
                    </button>
                  </Link>
                }
              </div>
          </div>
        </form>
      </section>
    </AppLayout>
  );
};

export default AddInfluencer;
