import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa6";

const AllInfluencers = () => {
  const { getinfluencers, deleteinfluencer, isLoading } = useContext(AppContext);

  const [influencers, setInfluencers] = useState([]);
  const [tableLoading, settableLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");

  const handleDelete = async (todelete) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to delete?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete",
        });

        if (result.isConfirmed) {
          let response = await deleteinfluencer(todelete);
          if (response === "success") {
            fetchInfluencers();
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "Location", accessor: "location" },
      { Header: "Category", accessor: "category" },
      // { Header: "Instagram", accessor: "instagram" },
      // {
      //   Header: "Instagram",
      //   accessor: "instagramposts",
      //   Cell: ({ row }) => (
      //     <div>
      //       <Link className="text-edit" to={`/add-influencer/${row.original.influencerid}`}>
      //         Edit
      //       </Link>
      //       &nbsp;
      //       <button className="text-delete" onClick={() => handleDelete(row.original.influencerid)}>
      //         Delete
      //       </button>
      //     </div>
      //   ),
      // },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div>
            <Link className="text-edit" to={`/add-influencer/${row.original.influencerid}`}>
              Edit
            </Link>
            &nbsp;
            <button className="text-delete" onClick={() => handleDelete(row.original.influencerid)}>
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const fetchInfluencers = async () => {
    const response = await getinfluencers(statusFilter);
    setInfluencers(response);
  };

  useEffect(() => {
    fetchInfluencers();
  }, [statusFilter]);

  return (
    <AppLayout pageName="All Influencers">
      <section>
        <div className='flex justify-between'>
          <div className="mb-4">
            <Link to={'/add-influencer'} className="btn btn-new"><FaPlus className='mr-1.5'/> Add New</Link>
          </div>
          {/* <div className="mb-4">
            <div className='flex items-center border rounded-lg space-x-2 pl-2'>
              <div><label htmlFor="statusFilter">Filter by Status</label></div>
              <select id="statusFilter" name="statusFilter" className="form-control !w-auto" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                <option value="">All</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Under Review">Under Review</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
          </div> */}
        </div>
        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <DataTable data={influencers} columns={columns} />
        )}
      </section>
    </AppLayout>
  );
};

export default AllInfluencers;
