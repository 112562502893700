import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { BiSolidMessageRounded } from "react-icons/bi";
import Tooltip from "../components/Tooltip";

const MyAssignedCampaigns = () => {
  const { totalUnread, getmyassignedcampaigns, isLoading } = useContext(AppContext);

  const [assignedCampaigns, setAssignedCampaigns] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const countUnreadMessages = (campaignId, unreadMessages) => {
    return unreadMessages.filter((message) => message.campaignid === campaignId).length;
  };

  const genericSearch = (items, searchTerm) => {
    if (!searchTerm) {
      return items;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(lowerCaseSearchTerm)));
  };

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
    const filteredData = genericSearch(assignedCampaigns, searchValue);
    setData(filteredData);
  };

  useEffect(() => {
    const fetchAssigned = async () => {
      const response = await getmyassignedcampaigns(statusFilter);
      setAssignedCampaigns(response[0] ? response[0].assigned : []);
      setData(response[0] ? response[0].assigned : []);
    };
    fetchAssigned();
  }, [statusFilter]);

  return (
    <AppLayout pageName={"Assigned Campaigns"}>
      <section>
        <div className="flex justify-between flex-wrap">
          <div class="input-group mb-5">
            <label htmlFor="statusFilter">Search</label>
            <input className="form-control" value={searchTerm} onChange={(e) => handleSearch(e.target.value)} />
          </div>
          <div class="input-group mb-5">
            <label htmlFor="statusFilter">Filter by Status</label>
            <select id="statusFilter" name="statusFilter" className="form-control" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <option value="">All</option>
              <option value="Awaiting Approval">Awaiting Approval</option>
              <option value="Work in Progress">Work in Progress</option>
              <option value="Reviewing Video">Reviewing Video</option>
              <option value="Re-Submit Video">Re-Submit Video</option>
              <option value="Approved! Awaiting Payment">Approved! Awaiting Payment</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
        </div>
        {isLoading || !totalUnread ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <>
            {/* <DataTable className="campaigns" data={assignedCampaigns} columns={columns} /> */}
            <div className="space-y-5">
              {data.map((campaign, index) => {
                const temparr = ["Status", "Budget", "Deadline", "Song Link"];
                const tempres = [
                  campaign.status,
                  "$" + campaign.budget.toLocaleString("en-US"),
                  campaign.deadlinedate,
                  <a className="text-blue-400 hover:text-blue-500 hover:!underline" target={"_blank"} href={campaign.song}>
                    Visit Link
                  </a>,
                ];
                const count = countUnreadMessages(campaign.campaign, totalUnread);
                return (
                  <div key={index}>
                    <Link to={`/assigned-campaigns/${campaign.campaign}`}>
                      <div className={`p-5 rounded-xl bg-neutral-50 hover:bg-neutral-100 hover:shadow border transition-colors cursor-pointer`}>
                        <div className="inline-flex flex-row justify-start mb-4 space-x-3">
                          <div>
                            <h4 className="text-main text-xl font-bold">{campaign.title}</h4>
                          </div>
                          {count > 0 && (
                            <div>
                              <span class="bg-blue-50 border border-blue-100 text-main text-xs font-semibold px-2 py-1.5 rounded leading-none">
                                <span>{count}</span> Messages
                              </span>
                            </div>
                          )}
                          {campaign.status == "Completed" && <span class="bg-green-50 border border-green-200 text-green-500 text-xs font-semibold px-2 py-1.5 rounded leading-none">Campaign Completed</span>}
                        </div>
                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
                          {temparr.map((temp, index) => (
                            <div key={index}>
                              <h6 className="text-neutral-500 font-medium text-[11px] tracking-wide uppercase mb-0.5">{temparr[index]}</h6>
                              <p className="text-neutral-800 font-semibold">{tempres[index]}</p>
                            </div>
                          ))}
                        </div>
                        <hr className="mt-5 mb-1" />
                        <div>
                          <span className="text-xs text-neutral-400">{campaign.addedatdate}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </section>
    </AppLayout>
  );
};

export default MyAssignedCampaigns;
