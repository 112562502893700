import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, allowedto }) => {
  // Check if user is authenticated (you can modify this logic based on your implementation)
  const isAuthenticated = localStorage.getItem("userToken");
  const sessionToken = JSON.parse(localStorage.getItem("userToken"));
  let hasRequiredRole;
  if (isAuthenticated) {
    hasRequiredRole = allowedto ? allowedto.includes(JSON.parse(isAuthenticated).type) : true;
  }
  // const callowedto = (allowedto ? allowedto : True)
  // console.log(JSON.parse(isAuthenticated).type)
  return isAuthenticated ? hasRequiredRole ? children : (sessionToken.type == 'Customer' ? <Navigate to="/discover" replace /> : <Navigate to="/dashboard" replace /> ) : <Navigate to="/" replace />;
};

export default PrivateRoute;
