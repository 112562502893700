import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Swal from "sweetalert2";
import { BiSolidMessageRounded } from "react-icons/bi";
import Tooltip from "../components/Tooltip";

const AllCampaigns = () => {
  const { totalUnread, isUnreadLoading, getcampaigns, deletecampaign, isLoading } = useContext(AppContext);

  const [campaigns, setCampaigns] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  const countUnreadMessages = (campaignId, unreadMessages) => {
    return unreadMessages.filter((message) => message.campaignid === campaignId).length;
  };

  const handleDelete = async (campaign) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to delete?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete",
        });

        if (result.isConfirmed) {
          let response = await deletecampaign(campaign);
          if (response === "success") {
            fetchCampaigns();
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "campaignid",
        Cell: ({ row }) => {
          const count = countUnreadMessages(row.original.campaignid, totalUnread);
          return (
            <div className="flex space-x-2 flex-row">
              {row.original.status !== "In Progressx" ? (
                <>
                  <Link className="block btn-main !py-1 !px-3 !rounded-sm !text-[12px]" to={`/campaign-details/${row.original.campaignid}`}>
                    View Details
                  </Link>
                </>
              ) : (
                ""
              )}
              {count > 0 && (
                <Tooltip text={`${count} Unread Messages`}>
                  <Link className="inline-flex space-x-1 items-center rounded-sm bg-neutral-200 px-1.5 py-1.5 text-[12px] font-medium !text-neutral-700 leading-none" to={`/campaign-details/${row.original.campaignid}`}>
                    <BiSolidMessageRounded /> <span>{count}</span>
                  </Link>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      { Header: "Client", accessor: "company" },
      { Header: "Title", accessor: "title" },
      {
        Header: "Budget",
        accessor: "budget",
        Cell: ({ row }) => <div>${row.original.budget.toLocaleString("en-US")}</div>,
      },
      {
        Header: "Expected Reach",
        accessor: "reach",
        Cell: ({ row }) => <div>${row.original.reach.toLocaleString("en-US")}</div>,
      },
      { Header: "Status", accessor: "status" },
      { Header: "Added At", accessor: "addedatdate" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            {row.original.status != "Completed" && (
              <Link className="text-edit" to={`/add-campaign/${row.original.campaignid}`}>
                Edit
              </Link>
            )}
            <button className="text-delete" onClick={() => handleDelete(row.original.campaignid)}>
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const fetchCampaigns = async () => {
    const response = await getcampaigns(statusFilter);
    setCampaigns(response);
  };

  useEffect(() => {
    if (isUnreadLoading == false) {
      fetchCampaigns();
    }
  }, [statusFilter, isUnreadLoading]);

  return (
    <AppLayout pageName="All Campaigns">
      <section>
        <div className="mb-4">
          <label htmlFor="statusFilter" className="mb-1">
            Filter by Status
          </label>
          <select id="statusFilter" name="statusFilter" className="form-control" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="">All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Under Review">Under Review</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>

        {isLoading || isUnreadLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <DataTable data={campaigns} columns={columns} />
        )}
      </section>
    </AppLayout>
  );
};

export default AllCampaigns;
