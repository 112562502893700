import React, { Fragment, useContext, useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from "../config";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Modal from "../components/Modal";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { PiPlusCircle } from "react-icons/pi";
import "../styles/monitor.scss";
import Swal from 'sweetalert2';
import { HiMiniChevronDown } from "react-icons/hi2";
import { GoDotFill } from "react-icons/go";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import ApexCharts from 'react-apexcharts';
import { LuArrowUpRight } from "react-icons/lu";

const Settings = () => {
  const { isLoading } = useContext(AppContext);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [hoveredMenu, setHoveredMenu] = useState("");

  const pages = ["Manage your account", "Manage your team", "Change password", "Plans & Billing", "Order History", "Support", "Contact us"];
  const link = ["/profile", "/team", "/password", "/plans", "", "", ""];

  return (
    <AppLayout pageId="monitor" pageName={"Settings"}>
      <section>
        <div className="mt-16 p-2 flex flex-col space-y-10 text-2xl">
            { pages.map((page, index) => {
                return(
                    <Link to={link[index]}>
                        <div className={`flex transition-all cursor-pointer hover:pl-3 ${hoveredMenu != "" && (hoveredMenu != page && 'opacity-50')}`} onMouseOver={() => setHoveredMenu(page)} onMouseOut={() => setHoveredMenu('')}>{page} {(page == 'Support' || page == 'Contact us') && <LuArrowUpRight className="ml-4" size={16}/>}</div>
                    </Link>
                )
            }) }
        </div>
      </section>
    </AppLayout>
  );
};

export default Settings;
