import React from 'react';

const MultiSelect = ({ options, selectedOptions, setSelectedOptions, placeholder }) => {
  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value && !selectedOptions.includes(value)) {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  return (
    <div className="multi-select-container">
      <select onChange={handleSelectChange} defaultValue="">
        <option value="">
          {placeholder}
        </option>
        {options
          .filter(option => !selectedOptions.includes(option.value))
          .map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default MultiSelect;
