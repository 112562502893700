import AppLayout from "../layouts/AppLayout";
import React, { Fragment, useState, useEffect, useContext, useCallback } from "react";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import { PiUsersThree } from "react-icons/pi";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaCheck, FaTimes, FaUpload } from "react-icons/fa";
import { useDropzone } from "react-dropzone";

const AddCampaign = () => {
  const { campaignId } = useParams();

  const [campaign, setCampaign] = useState("");
  const [title, setTitle] = useState("");
  const [budget, setBudget] = useState("");
  const [reach, setReach] = useState("");
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [reviewaction, setReviewaction] = useState("");
  const [reviewnote, setReviewnote] = useState("");
  const [reviewactiondesc, setReviewactiondesc] = useState("");
  const [files, setFiles] = useState([]);

  const { addcampaign, getcampaign, reviewcampaign, updatecampaign, isLoading } = useContext(AppContext);

  useEffect(() => {
    const fetchCampaign = async () => {
      const response = await getcampaign({ campaignid: campaignId });
      if (response) {
        if (response[0].status != "Completed") {
          setCampaign(response[0]);
          setTitle(response[0].title);
          setBudget(response[0].budget);
          setReach(response[0].reach);
          setDescription(response[0].description);
          setLinks(response[0].links);
          setStatus(response[0].status);
        } else {
          navigate("/all-campaigns");
        }
      }
    };

    if (campaignId) {
      fetchCampaign();
    }
  }, [campaignId]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removeFile = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      try {
        if (title !== "" && budget !== "" && description !== "") {
          setError("");

          const formData = new FormData();
          formData.append("title", title);
          formData.append("budget", budget);
          formData.append("reach", reach);
          formData.append("description", description);
          formData.append("links", links);
          if (campaignId) {
            formData.append("campaignid", campaignId);
          }
          files.forEach((file) => formData.append("files", file));

          let response = "";
          if (campaignId) {
            response = await updatecampaign(formData);
          } else {
            response = await addcampaign(formData);
          }
          if (response === "success") {
            navigate("/my-campaigns");
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Error: " + error);
      }
    }
  };

  const reviewCampaign = async () => {
    const response = await reviewcampaign({ campaignid: campaignId, reviewaction, reviewnote });
    if (response === "success") {
      setModalopen(false);
      setReviewaction("");
      setReviewactiondesc("");
      navigate("/all-campaigns");
    }
  };

  const prepareaction = (action, desc) => {
    setModalopen(true);
    setReviewaction(action);
    setReviewactiondesc(desc);
  };

  const unprepareaction = () => {
    setModalopen(false);
    setReviewaction("");
  };

  return (
    <AppLayout pageName="Campaign Details">
      {campaign != "" ? (
        <section>
          <div className="flex flex-row justify-between">
            <div>
              <h6 className="text-sm font-medium text-neutral-500 mb-0 leading-none">TITLE</h6>
              <h2 className="text-3xl font-semibold tracking-normal">{campaign.title}</h2>
            </div>
            <div className="text-right">
              <h6 className="text-sm font-medium text-neutral-500 mb-0 leading-none">STATUS</h6>
              <h2 className="text-3xl font-semibold tracking-normal">{campaign.status}</h2>
            </div>
          </div>
          {status == "Under Review" ? (
            <>
              <div className="flex items-center p-4 my-5 text-sm text-red-500 rounded-lg bg-red-100" role="alert">
                <div className="font-medium">This campaign needs to be reviewed by the admin and sent for approval to the client. Please make the required changes and save the campaign, it will be sent to the client.</div>
              </div>

              <Transition.Root show={modalopen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={unprepareaction}>
                  <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 hidden bg-gray-50 bg-opacity-75 blur-xl transition-opacity md:block" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                      <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enterTo="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 md:scale-100" leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
                        <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg">
                          <div className="relative w-full items-center overflow-hidden bg-neutral-800 rounded-lg text-white shadow-2xl p-8">
                            <div className="w-full">
                              <h5 className="text-xl font-medium mb-2">{reviewactiondesc}</h5>
                              <h5 className="text-md text-neutral-400 font-light">This action cannot be undone</h5>
                              <textarea className="form-control resize-none mt-3" placeholder="Any Note.." rows={4} value={reviewnote} onChange={(e) => setReviewnote(e.target.value)}></textarea>
                              <button type="button" className="absolute top-3 right-3 text-gray-400 hover:text-gray-500" onClick={() => unprepareaction()}>
                                {" "}
                                <span className="sr-only">Close</span> <RxCross2 className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                            {reviewaction != "" ? (
                              <div className="px-0 pt-7 mb-3 flex justify-end space-x-2">
                                <button disabled={isLoading} className="btn-main-inverse" onClick={() => unprepareaction()}>
                                  No, Cancel
                                </button>
                                <button disabled={isLoading} className="btn-main !px-6" onClick={() => reviewCampaign()}>
                                  {isLoading === false ? "Yes, Proceed" : <Spinner animating={isLoading} />}
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`flex flex-row flex-wrap w-full ${status == "Under Reviewx" ? "pointer-events-none" : ""}`}>
            <div className="w-full p-2">
              <label htmlFor="title">Campaign Title</label>
              <input id="title" name="title" required type="text" className="form-control" placeholder="Promoting my product" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div className="w-2/4 p-2">
              <label htmlFor="budget">Budget</label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-400">$</span>
                </div>
                <input id="budget" name="budget" required type="number" className="form-control !pl-7" placeholder="500" value={budget} onChange={(e) => setBudget(e.target.value)} />
              </div>
            </div>
            <div className="w-2/4 p-2">
              <label htmlFor="reach">How much reach do you expect?</label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-400">
                    <PiUsersThree className="-mt-1" size={22} />
                  </span>
                </div>
                <input id="reach" name="reach" type="number" className="form-control !pl-10" placeholder="10,000" value={reach} onChange={(e) => setReach(e.target.value)} />
              </div>
            </div>
            <div className="w-full p-2">
              <label htmlFor="description">Description</label>
              <textarea id="description" rows={5} name="description" required type="text" className="form-control" placeholder="Please tell us more about the campaign" defaultValue={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>
            <div className="w-full p-2">
              <label htmlFor="links">Links (optional)</label>
              <textarea id="links" rows={3} name="links" type="text" className="form-control" placeholder="Please enter some links if you have" defaultValue={links} onChange={(e) => setLinks(e.target.value)}></textarea>
            </div>

            <div className="p-2 w-full">
              <div {...getRootProps({ className: "dropzone" })}>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-neutral-300 hover:border-[#004679] px-6 py-10 cursor-pointer">
                  <div className="text-center">
                    <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                    </svg>
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                        <span>Upload a file</span>
                        <input {...getInputProps()} />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
              </div>
              <aside>
                <table className="w-full text-sm text-neutral-700 font-medium tracking-wide sm:w-2/4 mt-2">
                  {files.map((file, index) => (
                    <tr key={index}>
                      <td>
                        <span>{file.path.length > 30 ? file.path.substring(0, 30) + "..." : file.path.substring(0, 30)}</span>
                      </td>
                      <td className="flex justify-between">
                        <span className="text-neutral-400 text-xs">
                          {file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)} - {Math.round(file.size / 1024)} kb
                        </span>{" "}
                        <button className="text-red-500 text-xs ml-3" onClick={() => removeFile(file)}>
                          <FaTimes />
                        </button>
                      </td>
                    </tr>
                  ))}
                </table>
              </aside>
            </div>

            <div className="px-2 pb-2">
              {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
              {campaign != "" ? (
                <div className="flex space-x-4 mb-5">
                  <button onClick={() => prepareaction("Approval", "You won't be able to update campaign details before the Client approves it. You sure to proceed?")} type="button" className="btn-submit !bg-green-600  !border-green-600" disabled={isLoading}>
                    <span>Save &amp; ask for Approval</span>
                    <span>{isLoading === false ? <FaCheck /> : <Bounce animating={isLoading} />}</span>
                  </button>
                  <button onClick={() => prepareaction("Denied. Please Re-Submit", "Any changes will not be saved and the Client will be notified to resubmit it.")} type="button" className="btn-cancel" disabled={isLoading}>
                    <span>Ask to Re-Submit</span>
                    <span>{isLoading === false ? <FaTimes /> : <Bounce animating={isLoading} />}</span>
                  </button>
                </div>
              ) : (
                <button type="submit" className="btn-submit" disabled={isLoading}>
                  <span>Submit</span>
                  <span>{isLoading === false ? <HiArrowNarrowRight /> : <Bounce animating={isLoading} />}</span>
                </button>
              )}
            </div>
          </form>
        </section>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default AddCampaign;
