import React, { useState } from "react";
import { IoChatbubblesOutline } from "react-icons/io5";

const ChatBox = ({ children, messages }) => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  return (
    <>
      {messages.length > 0 ? (
        <div className="p-3 flex flex-1">
          <div className="space-y-2 w-full">
            {messages.reduce((acc, message, index, array) => {
              const formatDate = (date) => {
                return new Date(date).toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" });
              };

              const currentMessageDate = formatDate(message.addedat);
              const previousMessageDate = index > 0 ? formatDate(array[index - 1].addedat) : null;
              const formattedTime = new Date(message.addedat).toLocaleTimeString([], { timeStyle: "short" });

              // Check if the current message date is different from the previous message's date
              if (currentMessageDate !== previousMessageDate) {
                acc.push(
                  <div key={`date-${index}`} className="text-center text-xs text-neutral-500 font-medium my-2">
                    {currentMessageDate}
                  </div>
                );
              }

              acc.push(
                <div key={index}>
                  {message.sender === userToken.userid ? (
                    <div className="w-2/3 flex ml-auto justify-end">
                      <div className="flex flex-row w-full space-x-1 items-end justify-end">
                        <span className="text-[10px] font-medium text-neutral-500">{formattedTime}</span>
                        <div className="py-2 rounded-l-xl rounded-t-xl bg-main text-white text-sm pr-4 pl-5 font-normal tracking-wide">{message.message}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-2/3 flex mr-auto justify-start">
                      <div className="flex flex-row w-full space-x-1 items-end">
                        <div className="py-2 rounded-r-xl rounded-b-xl bg-neutral-200 text-neutral-700 text-sm pl-4 pr-5 font-medium">{message.message}</div>
                        <span className="text-[10px] font-medium text-neutral-500">{formattedTime}</span>
                      </div>
                    </div>
                  )}
                </div>
              );

              return acc;
            }, [])}
          </div>
        </div>
      ) : (
        <div className="p-3 h-full flex flex-1">
          <div className="flex flex-1 w-full h-full justify-center items-center text-neutral-500 font-normal">
            <div className="text-center">
              <div className="flex justify-center mb-3">
                <IoChatbubblesOutline size={56} />
              </div>
              <div>Start a conversation</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBox;
