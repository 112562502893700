import AppLayout from "../layouts/AppLayout";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { AppContext } from "../contexts/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { PiUsersThree } from "react-icons/pi";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useDropzone } from "react-dropzone";

const NewCampaign = () => {
  const { campaignId } = useParams();

  const [campaign, setCampaign] = useState(null);
  const [title, setTitle] = useState("");
  const [budget, setBudget] = useState("");
  const [reach, setReach] = useState("");
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [reviewaction, setReviewaction] = useState("");
  const [files, setFiles] = useState([]);

  const { userToken, getmycampaign, newcampaign, updatemycampaign, isLoading } = useContext(AppContext);

  useEffect(() => {
    const fetchCampaign = async () => {
      const response = await getmycampaign(campaignId);
      if (response.length > 0) {
        setCampaign(response[0]);
        setTitle(response[0].title);
        setBudget(response[0].budget);
        setReach(response[0].reach);
        setDescription(response[0].description);
        setLinks(response[0].links);
        setStatus(response[0].status);
      }
    };

    fetchCampaign();
  }, [campaignId]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removeFile = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      try {
        if (title !== "" && budget !== "" && description !== "") {
          setError("");

          const formData = new FormData();
          formData.append("title", title);
          formData.append("budget", budget);
          formData.append("reach", reach);
          formData.append("description", description);
          formData.append("links", links);
          formData.append("userid", userToken.userid);
          if (campaignId) {
            formData.append("campaignid", campaignId);
          }
          files.forEach((file) => formData.append("files", file));

          let response = "";
          if (campaignId) {
            response = await updatemycampaign(formData);
          } else {
            response = await newcampaign(formData);
          }
          if (response === "success") {
            navigate("/my-campaigns");
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Error: " + error);
      }
    }
  };

  const prepareaction = (action) => {
    setModalopen(true);
    setReviewaction(action);
  };

  return (
    <AppLayout pageName="New Campaign">
      <section>
        {status === "Under Review" ? (
          <>
            <div className="flex items-center p-4 mb-5 text-sm text-red-500 rounded-lg bg-red-100" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">Under Review!</span> This member needs to be reviewed by the admin.
              </div>
            </div>

            <div className="flex space-x-4 mb-5">
              <button onClick={() => prepareaction("Approved")} type="button" className="btn-submit !bg-green-600  !border-green-600" disabled={isLoading}>
                <span>Approve</span>
                <span>{isLoading === false ? <FaCheck /> : <Bounce animating={isLoading} />}</span>
              </button>
              <button onClick={() => prepareaction("Rejected")} type="button" className="btn-submit !bg-red-500  !border-red-500" disabled={isLoading}>
                <span>Reject</span>
                <span>{isLoading === false ? <FaTimes /> : <Bounce animating={isLoading} />}</span>
              </button>
            </div>
          </>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`flex flex-row flex-wrap w-full ${status === "Under Review" ? "pointer-events-none" : ""}`}>
          <div className="w-full p-2">
            <label htmlFor="title">Campaign Title</label>
            <input id="title" name="title" required type="text" className="form-control" placeholder="Promoting my product" value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className="w-2/4 p-2">
            <label htmlFor="budget">Budget</label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-400">$</span>
              </div>
              <input id="budget" name="budget" required type="number" className="form-control !pl-7" placeholder="500" value={budget} onChange={(e) => setBudget(e.target.value)} />
            </div>
          </div>
          <div className="w-2/4 p-2">
            <label htmlFor="reach">How much reach do you expect?</label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-400">
                  <PiUsersThree className="-mt-1" size={22} />
                </span>
              </div>
              <input id="reach" name="reach" type="number" className="form-control !pl-10" placeholder="10,000" value={reach} onChange={(e) => setReach(e.target.value)} />
            </div>
          </div>
          <div className="w-full p-2">
            <label htmlFor="description">Description</label>
            <textarea id="description" rows={5} name="description" required type="text" className="form-control" placeholder="Please tell us more about the campaign" defaultValue={description} onChange={(e) => setDescription(e.target.value)}></textarea>
          </div>
          <div className="w-full p-2">
            <label htmlFor="links">Links (optional)</label>
            <textarea id="links" rows={3} name="links" type="text" className="form-control" placeholder="Please enter some links if you have" defaultValue={links} onChange={(e) => setLinks(e.target.value)}></textarea>
          </div>

          <div className="p-2 w-full">
            <div {...getRootProps({ className: "dropzone" })}>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-neutral-300 hover:border-[#004679] px-6 py-10 cursor-pointer">
                <div className="text-center">
                  <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                  </svg>
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                      <span>Upload a file</span>
                      <input {...getInputProps()} />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                </div>
              </div>
            </div>
            <aside>
              <table className="w-full text-sm text-neutral-700 font-medium tracking-wide sm:w-2/4 mt-2">
                {files.map((file, index) => (
                  <tr key={index}>
                    <td>
                      <span>{file.path.length > 30 ? file.path.substring(0, 30) + "..." : file.path.substring(0, 30)}</span>
                    </td>
                    <td className="flex justify-between">
                      <span className="text-neutral-400 text-xs">
                        {file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)} - {Math.round(file.size / 1024)} kb
                      </span>{" "}
                      <button className="text-red-500 text-xs ml-3" onClick={() => removeFile(file)}>
                        <FaTimes />
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </aside>
          </div>

          <div className="px-2 pb-2">
            {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
            {status === "Under Review" ? (
              ""
            ) : (
              <button type="submit" className="btn-submit" disabled={isLoading}>
                <span>Submit</span>
                <span>{isLoading === false ? <HiArrowNarrowRight /> : <Bounce animating={isLoading} />}</span>
              </button>
            )}
          </div>
        </form>
      </section>
    </AppLayout>
  );
};

export default NewCampaign;
