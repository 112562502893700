import AppLayout from "../layouts/AppLayout";
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PiUsersThree } from "react-icons/pi";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaPlus } from "react-icons/fa";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { LiaPhoneSolid } from "react-icons/lia";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { MdAttachMoney } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import { IoIosLink } from "react-icons/io";
import { IoChatbubblesOutline } from "react-icons/io5";
import { IoCheckmarkDone } from "react-icons/io5";
import { VscSend } from "react-icons/vsc";
import { FaLink } from "react-icons/fa6";
import { FaCheckDouble } from "react-icons/fa6";
import { CgSpinner } from "react-icons/cg";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import "../styles/campaigns.scss";
import ScrollToBottom from "react-scroll-to-bottom";
import { BsChevronLeft } from "react-icons/bs";
import Swal from "sweetalert2";
import Tooltip from "../components/Tooltip";
import ChatBox from "../components/ChatBox";
import { TfiTrash } from "react-icons/tfi";
import { HiMiniArrowLeft, HiMiniArrowRight } from "react-icons/hi2";

const CompaignDetails = () => {
  const { campaignId } = useParams();

  const navigate = useNavigate();

  const [campaign, setCampaign] = useState("");
  const [client, setClient] = useState("");
  const [showSection, setShowSection] = useState(false);
  const [showinfSection, setShowinfSection] = useState(false);
  const [formloader, setformloader] = useState(false);
  const [informerror, setinformerror] = useState("");
  const [refreshloader, setrefreshloader] = useState("");
  const [assignedinfluencers, setAssignedinfluencers] = useState([]);
  const [assignedinf, setAssignedinf] = useState([]);
  const [newinfluencers, setnewinfluencers] = useState([]);
  const [newinfluencer, setnewinfluencer] = useState("");
  const [newdescription, setnewdescription] = useState("");
  const [newreach, setnewreach] = useState("");
  const [newbudget, setnewbudget] = useState("");
  const [newdeadline, setnewdeadline] = useState("");
  const [inftoreview, setinftoreview] = useState("");
  const [newsong, setnewsong] = useState("");
  const [newnote, setnewnote] = useState("");
  const [submissionstat, setsubmissionstat] = useState("");
  const [showCounterForm, setShowCounterForm] = useState(false);
  const [toDelete, settoDelete] = useState('');

  const { socket, fetchUnreadMessages, getinfluencers, assigninfluencer, getmycampaignlists, getmycampaignlistsinfluencers, unassigninfluencer, assigninf, getcampaigninf, updateinfluencercampaignlistid, unassigninf, finaliseinf, getmycampaign, removefromcampaignlist, getclient, addrecounteroffer, rejectvideo, approvevideo, refreshvideoanalytics, completecampaign, isLoading } = useContext(AppContext);

  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [messageReceiver, setMessageReceiver] = useState("");
  const [chatusername, setchatusername] = useState("");
  const [chatuserimage, setchatuserimage] = useState("");
  const [conversationList, setConversationList] = useState([]);
  const [campaignLists, setCampaignLists] = useState([]);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const toggleinfSection = () => {
    if (showinfSection == false) {
      const fetchInfluencers = async () => {
        const response = await getinfluencers("Active");
        if (response.length === 0) {
          setformloader(false);
        }
        setnewinfluencers(response);
      };

      fetchInfluencers();
      setShowinfSection(!showinfSection);
    } else {
      setShowinfSection(!showinfSection);
    }
  };

  const toggleSection = () => {
    if (showSection == false) {
      const fetchInfluencers = async () => {
        const response = await getinfluencers("Active");
        if (response.length === 0) {
          setformloader(false);
        }
        setnewinfluencers(response);
      };

      fetchInfluencers();
      setShowSection(!showSection);
    } else {
      setShowSection(!showSection);
    }
  };

  const toggleCounterFormSection = (b, d, desc) => {
    if (showCounterForm == false) {
      setnewbudget(b);
      setnewdeadline(d);
      setnewdescription(desc);
      setShowCounterForm(!showCounterForm);
    } else {
      setnewbudget("");
      setnewdeadline("");
      setnewdescription("");
      setShowCounterForm(!showCounterForm);
    }
  };

  const fetchCampaignLists = async () => {
    const response = await getmycampaignlists(campaignId);
    if (response) {
      // setCampaignLists(response);
      // const data = response.map(campaign => {
      //     return {
      //         ...campaign,
      //         influencers: JSON.parse(`[${campaign.influencers}]`)
      //     };
      // });
      setCampaignLists(response);
    }

    // const response2 = await getmycampaignlistsinfluencers(campaignId);
    // if (response2) {
    //   setCampaignLists(response);
    // }
  };

  const fetchAssignedinf = async () => {
    const response = await getcampaigninf(campaignId);
    if (response) {
      setAssignedinf(response.influencers);
      setinftoreview(response.inftoreview);
    }
  };

  useEffect(() => {
    fetchCampaignLists();
    // fetchAssignedinfluencers();
    // fetchAssignedinf();
  }, [campaignId]);

  const fetchCampaign = async () => {
    const response = await getmycampaign(campaignId);
    if (response) {
      if (response[0] != "") {
        setCampaign(response[0]);
      } else {
        navigate("/campaigns");
      }
    } else {
      navigate("/campaigns");
    }
  };

  useEffect(() => {
    if (campaignId) {
      fetchCampaign();
    }
  }, []);

  const moveInfluencer = async (influencerid, oldlistid, direction) => {
    const currentListIndex = campaignLists.findIndex(list => list.campaignslistid === oldlistid);
    const newListIndex = direction === 'right' ? currentListIndex + 1 : currentListIndex - 1;
  
    if (newListIndex < 0 || newListIndex >= campaignLists.length) return; // Disable if out of bounds
  
    const newlistid = campaignLists[newListIndex].campaignslistid;

    const response = await updateinfluencercampaignlistid(influencerid, campaignId, oldlistid, newlistid);
    if (response && response === "success") {
  
      setCampaignLists(prevLists => {
        const updatedLists = [...prevLists];
        const oldList = updatedLists[currentListIndex];
        const newList = updatedLists[newListIndex];
    
        const influencer = oldList.influencers.find(inf => inf.influencerid === influencerid);
        if (!influencer) return updatedLists; // If influencer not found, return current state
    
        oldList.influencers = oldList.influencers.filter(inf => inf.influencerid !== influencerid);
        newList.influencers = newList.influencers.filter(inf => inf !== null); // Ensure no null values
        newList.influencers = [...newList.influencers, influencer];
    
        return updatedLists;
      });

    }
  };

  const handleDelete = async (todelete, listid) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to remove?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Remove'
        });
  
        if (result.isConfirmed) {
          const response = await removefromcampaignlist(todelete, listid, campaignId);
          console.log(response);
          if (response === 'success') {
  
            setCampaignLists(prevLists => {
              const updatedLists = prevLists.map(list => {
                if (list.campaignslistid === listid) {
                  return {
                    ...list,
                    influencers: list.influencers.filter(inf => inf.influencerid !== todelete)
                  };
                }
                return list;
              });
              return updatedLists;
            });

          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };
  

    // Update in the backend
    // const response = await updateInfluencerList(influencerid, oldlistid, newlistid);
    // if (response && response.message === "success") {
    //   // Update state
    //   setCampaignLists(prevLists => {
    //     const updatedLists = [...prevLists];
    //     const oldList = updatedLists[currentListIndex];
    //     const newList = updatedLists[newListIndex];
  
    //     const influencer = oldList.influencers.find(inf => inf.influencerid === influencerid);
    //     oldList.influencers = oldList.influencers.filter(inf => inf.influencerid !== influencerid);
    //     newList.influencers = [...newList.influencers, influencer];
  
    //     return updatedLists;
    //   });
    // }
  // };  

  return (
    <AppLayout pageId="campaign-details" pageName="Campaign Details">
      {campaign ? (
        <>
          {/* {campaign.status == "Completed" && (
            <div id="alert-additional-content-1" className="p-5 mb-2 text-main border border-blue-100 rounded-lg bg-blue-50" role="alert">
              <div className="flex items-center">
                <h3 className="text-2xl tracking-wide font-semibold">This offer has been completed</h3>
              </div>
              <div className="mt-1 text-base text-medium">You can't make any changes to it. The campaign questions box is still active.</div>
            </div>
          )} */}
          <section className="details-section">
            <div className="flex justify-between">
              <div>
                <div><Link to={'/campaigns'}><button className="btn-main-alt flex items-center !text-xs !w-auto !px-5"><GoArrowLeft size={16} className="mr-1.5"/> <span>Back to all campaigns</span></button></Link></div>
                <div className="page-head mt-5">
                    <h4>{campaign.name}</h4>
                </div>
                <div>
                  <div className="flex space-x-5">
                    <div>
                      <button className="btn-main-alt !text-sm">{campaign.totalinfluencers} influencers in this campaign</button>
                    </div>
                    <div>
                      <button className="btn-main-alt !text-sm">Start {campaign.startdate}</button>
                    </div>
                    <div>
                      <button className="btn-main-alt !text-sm">End {campaign.enddate}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right">
                {/* <h2 className="text-3xl font-semibold tracking-normal">{campaign.status}</h2> */}
              </div>
            </div>
            <div className="campaign-lists flex flex-1 space-x-4 justify-start overflow-y-auto flex-nowrap mt-10">
              {campaignLists.map((campaign, index) => {
                return(
                  <>
                  <div className="flex flex-col flex-1 space-y-3">
                    <div className="bg-main-alt py-4 px-5 rounded-md whitespace-nowrap text-xs font-semibold tracking-wide text-black uppercase w-[20rem]">
                        {campaign.name} {campaign.influencers.length > 0 && <span class="bg-blue-700 text-white text-xs font-medium ml-3 px-1.5 py-1 rounded">{campaign.influencers.length}</span> }
                    </div>
                    <div className="bg-main-alt p-4 rounded-md w-[20rem] list-body">
                      <div className="space-y-4">
                        {(campaign.influencers).map((inf) => {
                          const locationParts = inf.location ? inf.location.split(',') : [];
                          return(
                            <div className="p-2.5 rounded-md space-y-3 bg-white inf-card-sm">
                              <div className="flex justify-between">
                                <div className="flex space-x-3">
                                  <div>{ inf.profileimage !== '' ? <img className="profile" src={`data:image/png;base64,${inf.profileimage}`}/> : <div className="img-placeholder"><span>{inf.name[0]}</span></div> }</div>
                                  <div>
                                    <div className="font-bold text-xs">{inf.name}</div>
                                    <div className="text-[10px] font-semibold text-neutral-500">@{inf.instagram}</div>
                                  </div>
                                </div>
                                <div>
                                  <div className="flex space-x-1">
                                    <HiMiniArrowLeft
                                      className={`cursor-pointer ${index === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                      onClick={() => moveInfluencer(inf.influencerid, campaign.campaignslistid, 'left')}
                                    />
                                    <HiMiniArrowRight
                                      className={`cursor-pointer ${index === campaignLists.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                      onClick={() => moveInfluencer(inf.influencerid, campaign.campaignslistid, 'right')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="flex space-x-3 items-center">
                                  <div className="flex space-x-1.5 social-icons w-[70px]">
                                      {inf.tiktok != '' && <img src="/images/icons/tiktok-sm.png"/>}
                                      {inf.instagram != '' && <img src="/images/icons/instagram-sm.png"/>}
                                      {inf.youtube != '' && <img src="/images/icons/youtube-sm.png"/>}
                                      {inf.twitch != '' && <img src="/images/icons/twitch-sm.png"/>}
                                  </div>
                                  { locationParts.length > 0 && 
                                  <div className="text-[9px] bg-main-alt py-0.5 px-2 rounded-full font-semibold">{locationParts.length > 0 && <span>{locationParts[0]}</span>}</div>
                                  }
                                </div>
                                <div>
                                  <TfiTrash className=" hover:text-red-500 cursor-pointer" onClick={(e) => { e.stopPropagation(); handleDelete(inf.influencerid, campaign.campaignslistid)} } size={16}/>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>  
                    </div>
                  </div>
                  </>
                )
              })}
            </div>
          </section>
        </>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default CompaignDetails;
