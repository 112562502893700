import React, { useContext, useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { PiSlidersHorizontal, PiSlidersHorizontalFill } from "react-icons/pi";
import MultiSelect from "../components/MultiSelect"; // Ensure to create this file for custom styles
import "../styles/discover.scss";
import { PiUsers } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { LuArrowUpRight } from "react-icons/lu";
import { HiMiniChevronDown } from "react-icons/hi2";
import { TbChecks } from "react-icons/tb";
import { FiSearch } from "react-icons/fi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { PiStarFour } from "react-icons/pi";

const options = {
  tier: [
    { value: 'Tier 1', label: 'Tier 1' },
    { value: 'Tier 2', label: 'Tier 2' },
    { value: 'Tier 3', label: 'Tier 3' },
  ],
  location: [
    { value: 'Sweden', label: 'Sweden' },
    { value: 'USA', label: 'USA' },
    { value: 'Canada', label: 'Canada' },
  ],
  category: [
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Food', label: 'Food' },
  ],
  gender: [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ],
  platform: [
    { value: 'TikTok', label: 'TikTok' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'YouTube', label: 'YouTube' },
    { value: 'Twitch', label: 'Twitch' },
  ],
};

const Discover = () => {
  const { getinfluencers, getinfluencer, getlists, sharewithteam, saveit, unsaveit, savetolist, isLoading } = useContext(AppContext);

  const [influencers, setInfluencers] = useState([]);
  const [lists, setLists] = useState([]);
  const [list, setList] = useState('');
  const [influencer, setInfluencer] = useState("");
  const [infId, setInfId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [infLoading, setinfLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sharedAlready, setsharedAlready] = useState(0);
  const [savedAlready, setsavedAlready] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showListsDropdown, setShowListsDropdown] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const limit = 10;

  const genericSearch = (items, searchTerm) => {
    if (!searchTerm) {
      return items;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.filter((item) =>
      Object.values(item).some((value) =>
        value && value.toString().toLowerCase().includes(lowerCaseSearchTerm)
      )
    );
  };

  const fetchInfluencers = useCallback(async () => {
    if (!hasMore) return;
    setLoadingMore(true);
    const response = await getinfluencers(searchTerm, limit, offset);
    if (response.length > 0) {
      setInfluencers((prev) => [...prev, ...response]);
      setOffset((prev) => prev + limit);
    } else {
      setHasMore(false);
    }
    setLoadingMore(false);
  }, [getinfluencers, searchTerm, offset, hasMore]);

  const debouncedFetchInfluencers = useCallback(debounce(fetchInfluencers, 300), [searchTerm]);

  useEffect(() => {
    debouncedFetchInfluencers();
  }, [debouncedFetchInfluencers]);

  const handleSearch = (searchValue) => {
    setInfluencers([]);
    setOffset(0);
    setSearchTerm(searchValue);
    setHasMore(true);
  };

  useEffect(() => {
    const fetchInfluencer = async () => {
      if(infId != ''){
        setinfLoading(true);
        setsharedAlready(0);
        setsavedAlready(0);
        const response = await getinfluencer(infId);
        setInfluencer(response[0]);
        setinfLoading(false);
      }
    };

    fetchInfluencer();
  }, [infId]);

  const handleRemove = (value) => {
    setSelectedOptions(selectedOptions.filter(option => option !== value));
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      fetchInfluencers();
    }
  };

  const shareWithTeam = async () => {
    if(infId != ''){
      const response = await sharewithteam(infId);
      if(response == 'success'){
        setsharedAlready(1);
      }
    }
  }

  const saveIt = async () => {
    if(infId != ''){
      const response = await saveit(infId);
      if(response == 'success'){
        setsavedAlready(1);
      }
    }
  }

  const unsaveIt = async () => {
    if(infId != ''){
      const response = await unsaveit(infId);
      if(response == 'success'){
        setsavedAlready(0);
      }
    }
  }

  const saveToList = async (listId) => {
    if(infId != ''){
      const response = await savetolist(infId, listId);
      if(response == 'success'){
        setShowListsDropdown(false);
      }
    }
  }

  const fetchLists = async () => {
    const response = await getlists();
    setLists(response);
  };

  useEffect(() => {
    fetchLists();
  }, []);

  return (
    <AppLayout pageId="discover" pageName={"Discover influencers"}>
      <section>
        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <div className="space-y-5">
            <div className="flex space-x-8">
              <div className={`transition-all ${infId != '' ? 'w-4/6' : 'w-full'}`}>
                <div className="section-left flex flex-col">
                  <div className="page-head">
                      <h4>Discover influencers</h4>
                  </div>
                  <div className="inf-filters">
                    <div className="flex space-x-5">
                      <button className="btn-accent flex items-center"><PiStarFour className="mr-3"/> <span>Poppiq Influencer Finder</span></button>
                      <div className="search flex-1">
                        {/* <img src="/images/icons/svgs/search-normal.svg" width="15" alt="Search"/> */}
                        <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
                        <FiSearch color="#999"/>
                        </div>
                        <input
                          className="form-controlx"
                          placeholder="Find by name, country, tier, etc."
                          value={searchTerm}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="filters mt-4">
                      <div className="flex flex-wrap justify-start items-center filters-select space-x-10">
                        <div>
                          <PiSlidersHorizontal size={24} color={'#C2C2C2'}/>
                        </div>
                        <MultiSelect
                          options={options.tier}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          placeholder="Tier"
                        />
                        <MultiSelect
                          options={options.location}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          placeholder="Location"
                        />
                        <MultiSelect
                          options={options.category}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          placeholder="Category"
                        />
                        <MultiSelect
                          options={options.gender}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          placeholder="Gender"
                        />
                        <MultiSelect
                          options={options.platform}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          placeholder="Platform"
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-start items-center">
                      { selectedOptions.length > 0 && 
                      <div className="mr-4 mt-[10px]">
                        <PiSlidersHorizontalFill size={24} color={'#C2C2C2'}/>
                      </div>
                      }
                      <div className="selected-tags">
                        {selectedOptions.map(option => (
                          <div key={option} className="tag">
                            {option}
                            <span className="remove" onClick={() => setSelectedOptions(selectedOptions.filter(o => o !== option))}>
                              x
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="inf-table-wrapper flex-1" onScroll={handleScroll}>
                    <table className="inf-table w-full">
                      <thead className="text-sm text-neutral-400">
                        <tr className="border-b-2 border-neutral-300">
                          <th scope="col"> Influencer </th>
                          <th scope="col"> Profiles </th>
                          <th scope="col"> Location </th>
                          <th scope="col"> Followers </th>
                          <th scope="col"> Likes </th>
                        </tr>
                      </thead>
                      <tbody>
                        { influencers.length > 0 ? 
                        <>
                        { influencers.map((inf, index) => { 
                          const locationParts = inf.location ? inf.location.split(',') : [];
                          return(
                            <tr key={index} className={`${infId == inf.influencerid && 'active-inf'}`} onClick={() => setInfId(inf.influencerid)}>
                              <td className="name">
                                  <div>{ inf.profileimage !== '' ? <img className="profile" src={`data:image/png;base64,${inf.profileimage}`}/> : <div className="img-placeholder"><span>{inf.name[0]}</span></div> }</div>
                                  <div>{inf.name}<br/><span>@{inf.instagram}</span></div>
                              </td>
                              <td className="profiles">
                                <div className="flex space-x-2 justify-center">
                                  {inf.tiktok != '' && <img src="/images/icons/tiktok.png"/>}
                                  {inf.instagram != '' && <img src="/images/icons/instagram.png"/>}
                                  {inf.youtube != '' && <img src="/images/icons/youtube.png"/>}
                                  {inf.twitch != '' && <img src="/images/icons/twitch.png"/>}
                                </div>
                              </td>
                              <td className="location">{locationParts.length > 0 && <span>{locationParts[0]}</span>} {locationParts.length > 1 && <span>{locationParts[1]}</span>}</td>
                              <td className="followers">{inf.tiktokfollowers}</td>
                              <td className="likes">{inf.tiktoklikes}</td>
                            </tr>
                          ) 
                          }) 
                        }
                        {loadingMore && (
                          <tr>
                            <td colSpan="5" className="!text-center !py-10">
                              <Spinner color="#CCCCCC" size={24} />
                            </td>
                          </tr>
                        )}
                        </>
                        :
                        <tr>
                            <td colSpan="5" className="!text-center !py-10">
                                <h6 className="text-base font-light text-neutral-400">no records found</h6>
                            </td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              { infId != '' && 
              <div className={`w-2/6`}>
                <div class="inf-card">
                  { infLoading ? <div className="flex justify-center w-full py-12"> <Spinner color="#CCCCCC" size={24} /> </div> : 
                  <>
                  <div className="w-full flex flex-1 flex-col justify-between">
                  <div>
                  <div class="w-full flex justify-between">
                      <div>
                        { savedAlready == 1 || influencer.ifsaved == 1 ? 
                        <button class="btn-share flex items-center space-x-1" onClick={unsaveIt}>
                            <FaHeart size={12}/> <span>Saved</span>
                        </button>
                        :
                        <button class="btn-share flex items-center space-x-1" onClick={saveIt}>
                            <FaRegHeart size={12}/> <span>Save</span>
                        </button>
                        }
                      </div>
                      <div class="flex justify-end space-x-3">
                        { sharedAlready == 1 || influencer.ifshared == 1 ? 
                        <button class="btn-share flex items-center space-x-2">
                            <span>Shared with team</span> <TbChecks size={16}/>
                        </button>
                        :
                        <button class="btn-share flex items-center space-x-3" onClick={shareWithTeam}>
                            <FiUsers size={16}/> <span>Share with team</span>
                        </button>
                        }
                        <div className="relative">
                          <div class={`btn-share flex items-center space-x-1 ${showListsDropdown && '!rounded-b-none'}`} onClick={() => setShowListsDropdown(!showListsDropdown)}>
                              <span>Save to list</span> <HiMiniChevronDown size={16}/>
                          </div>
                          { showListsDropdown && 
                          <div className="save-list-dropdown">
                            {
                            lists.map((list, index) => {
                              return(
                                <div className={`dropdown-item`} onClick={() => saveToList(list.listid)}>{list.name}</div>
                              )
                            })
                            }
                          </div> 
                          }
                        </div>
                      </div>
                  </div>
                  <div class="text-center mt-5">
                      <img src={`data:image/png;base64,${influencer.profileimage}`} alt="" class="profile"/>
                      <h5 class="title">{influencer.name}</h5>
                      <div className="flex justify-center space-x-3">
                        {(influencer && influencer.category) && ((influencer.category).split(', ').map((cat, index) => (
                          <span key={index} className="bg-[#f422ff] text-xs text-white rounded-full py-1.5 px-3 mb-3">{cat}</span>
                        )))}
                      </div>
                      {/* <h6 class="username"><a>@{influencer.instagram}</a></h6> */}
                  </div>
                  <div className="social-accounts w-11/12 ml-auto pl-3 mt-5">
                    <table className="w-full">
                      {influencer.tiktok != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8 mb-2">
                            <img src="/images/icons/tiktok.png" className="absolute left-0 h-full"/>
                            <div>{influencer.tiktok}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <h6>Likes</h6>
                            <h5>{influencer.tiktoklikes}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.tiktokfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.tiktokfollowing}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.instagram != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8 mb-2 mt-5">
                            <img src="/images/icons/instagram.png" className="absolute left-0 h-full"/>
                            <div>{influencer.instagram}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Posts</h6>
                              <h5>{influencer.instagramposts}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.instagramfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.instagramfollowing}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.youtube != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8 mb-2 mt-5">
                            <img src="/images/icons/youtube.png" className="absolute left-0 h-full"/>
                            <div>{influencer.youtube}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Videos</h6>
                              <h5>{influencer.youtubevideos}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Views</h6>
                              <h5>{influencer.youtubeviews}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.youtubesubscribers}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                      {influencer.twitch != '' &&
                      <>
                      <tr>
                        <td colSpan={3}>
                          <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8 mb-2 mt-5">
                            <img src="/images/icons/twitch.png" className="absolute left-0 h-full"/>
                            <div>{influencer.twitch}</div>
                            <LuArrowUpRight className="mx-auto" size={13}/>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                              <h6>Avg. Viewers</h6>
                              <h5>{influencer.twitchavgviews}</h5>
                          </div>
                        </td>
                        <td>
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.twitchfollowers}</h5>
                            </div>
                        </td>
                        <td>
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.twitchsubscribers}</h5>
                            </div>
                        </td>
                      </tr>
                      </>
                      }
                    </table>
                    {/*                     
                    <div className="flex flex-col space-y-5">
                      {influencer.tiktok != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/tiktok.png" className="absolute left-0 h-full"/>
                          <div>{influencer.tiktok}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Likes</h6>
                              <h5>{influencer.tiktoklikes}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.tiktokfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.tiktokfollowing}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.instagram != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/instagram.png" className="absolute left-0 h-full"/>
                          <div>{influencer.instagram}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Posts</h6>
                              <h5>{influencer.instagramposts}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.instagramfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Following</h6>
                              <h5>{influencer.instagramfollowing}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.youtube != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/youtube.png" className="absolute left-0 h-full"/>
                          <div>{influencer.youtube}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Videos</h6>
                              <h5>{influencer.youtubevideos}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Views</h6>
                              <h5>{influencer.youtubeviews}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.youtubesubscribers}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                      {influencer.twitch != '' &&
                      <div>
                        <button className="p-2 pr-4 pl-3 bg-highlight rounded-full text-neutral-900 text-xs relative font-semibold flex justify-between items-center space-x-8">
                          <img src="/images/icons/twitch.png" className="absolute left-0 h-full"/>
                          <div>{influencer.twitch}</div>
                          <LuArrowUpRight className="mx-auto" size={13}/>
                        </button>
                        <div className="flex justify-between gap-x-5 mt-3">
                          <div className="w-1/3">
                            <div>
                              <h6>Average Viewers</h6>
                              <h5>{influencer.twitchavgviews}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-center">
                            <div>
                              <h6>Followers</h6>
                              <h5>{influencer.twitchfollowers}</h5>
                            </div>
                          </div>
                          <div className="w-1/3 flex justify-end">
                            <div>
                              <h6>Subscribers</h6>
                              <h5>{influencer.twitchsubscribers}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                    </div> */}
                  </div>
                  {/*                   
                  <div class="boxes grid grid-cols-2">
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktoklikes}</h5>
                            <h6>Likes</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktokfollowers}</h5>
                            <h6>Followers</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5>{influencer.tiktokfollowing}</h5>
                            <h6>Following</h6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                            <h5><LuArrowUpRight className="mx-auto" size={26}/></h5>
                            <h6>Visit full profile</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                      { sharedAlready == 1 || influencer.ifshared == 1 ? 
                      <button class="mx-auto btn-share flex items-center space-x-2">
                          <span>Shared with Team</span> <TbChecks size={16}/>
                      </button>
                      :
                      <button class="mx-auto btn-share flex items-center space-x-3" onClick={shareWithTeam}>
                          <FiUsers size={16}/> <span>Share profile with team</span>
                      </button>
                      }
                  </div>
                   */}
                  </div>
                  {/* <div class="mt-5 other-profiles">
                      <div>Explore their other profiles</div>
                      <div class="mt-3">
                        <div className="flex space-x-3 justify-center">
                          {influencer.tiktok != '' && <img src="/images/icons/tiktok.png"/>}
                          {influencer.instagram != '' && <img src="/images/icons/instagram.png"/>}
                          {influencer.youtube != '' && <img src="/images/icons/youtube.png"/>}
                          {influencer.twitch != '' && <img src="/images/icons/twitch.png"/>}
                        </div>
                      </div>
                  </div> */}
                  </div>
                  </>
                  }
              </div>
            </div>
              }
            </div>
          </div>
        )}
      </section>
    </AppLayout>
  );
};

export default Discover;
