import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const GoogleAuthSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    if (code) {
      axios.post('http://localhost:3001/auth/google/token', { code })
        .then(response => {
          const { data } = response;
          localStorage.setItem('user', JSON.stringify(data.user));
          navigate('/'); // Redirect to home or dashboard
        })
        .catch(error => {
          console.error('Error exchanging code:', error);
          navigate('/'); // Redirect to home if error occurs
        });
    } else {
      navigate('/'); // Redirect to home if no code is found
    }
  }, [navigate]);

  return <div>Loading...</div>;
};

export default GoogleAuthSuccess;
