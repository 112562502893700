import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { BiSolidMessageRounded } from "react-icons/bi";
import Tooltip from "../components/Tooltip";

const MyCampaigns = () => {
  const { totalUnread, getmycampaigns, isLoading } = useContext(AppContext);

  const [campaigns, setCampaigns] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const countUnreadMessages = (campaignId, unreadMessages) => {
    return unreadMessages.filter((message) => message.campaignid === campaignId).length;
  };

  const genericSearch = (items, searchTerm) => {
    if (!searchTerm) {
      return items;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(lowerCaseSearchTerm)));
  };

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
    const filteredData = genericSearch(campaigns, searchValue);
    setData(filteredData);
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      const response = await getmycampaigns(statusFilter);
      setCampaigns(response);
      setData(response);
    };

    fetchCampaigns();
  }, [statusFilter]);

  return (
    <AppLayout pageName={"My Campaigns"}>
      <section>
        <div className="mb-5">
          <Link className="inline-block" to="/new-campaign">
            <button className="btn-main-inverse">
              Add new Campaign <HiArrowNarrowRight />
            </button>
          </Link>
        </div>

        <div className="flex justify-between flex-wrap">
          <div class="input-group mb-5">
            <label htmlFor="statusFilter">Search</label>
            <input className="form-control" value={searchTerm} onChange={(e) => handleSearch(e.target.value)} />
          </div>
          <div class="input-group mb-5">
            <label htmlFor="statusFilter">Filter by Status</label>
            <select id="statusFilter" name="statusFilter" className="form-control" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Under Review">Under Review</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <>
            {/* <DataTable className="campaigns" data={campaigns} columns={columns} /> */}
            <div className="space-y-5">
              {data.map((campaign, index) => {
                const temparr = ["Status", "Budget", "Expected Reach"];
                const tempres = [campaign.status, "$" + campaign.budget.toLocaleString("en-US"), campaign.reach.toLocaleString("en-US")];
                const count = countUnreadMessages(campaign.campaignid, totalUnread);
                return (
                  <div key={index}>
                    <Link to={`/my-campaigns/${campaign.campaignid}`}>
                      <div className={`p-5 rounded-xl bg-neutral-50 hover:bg-neutral-100 hover:shadow border transition-colors cursor-pointer`}>
                        <div className="inline-flex flex-row justify-start mb-4 space-x-3">
                          <div>
                            <h4 className="text-main text-xl font-bold">{campaign.title}</h4>
                          </div>
                          {count > 0 && (
                            <div>
                              <span class="bg-blue-50 border border-blue-100 text-main text-xs font-semibold px-2 py-1.5 rounded leading-none">
                                <span>{count}</span> Messages
                              </span>
                            </div>
                          )}
                          {campaign.status == "Completed" && <span class="bg-green-50 border border-green-200 text-green-500 text-xs font-semibold px-2 py-1.5 rounded leading-none">Campaign Completed</span>}
                        </div>
                        <div className="grid grid-cols-3">
                          {temparr.map((temp, index) => (
                            <div key={index}>
                              <h6 className="text-neutral-500 font-medium text-[11px] tracking-wide uppercase mb-0.5">{temparr[index]}</h6>
                              <p className="text-neutral-800 font-semibold">{tempres[index]}</p>
                            </div>
                          ))}
                        </div>
                        <hr className="mt-5 mb-1" />
                        <div>
                          <span className="text-xs text-neutral-400">{campaign.addedatdate}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </section>
    </AppLayout>
  );
};

export default MyCampaigns;
