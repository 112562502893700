import AppLayout from "../layouts/AppLayout";
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IoChatbubblesOutline } from "react-icons/io5";
import { IoCheckmarkDone } from "react-icons/io5";
import { VscSend } from "react-icons/vsc";
import { FaLink } from "react-icons/fa6";
import { CgSpinner } from "react-icons/cg";
import "../styles/chat.css";
import ScrollToBottom from "react-scroll-to-bottom";
import Swal from "sweetalert2";
import ChatBox from "../components/ChatBox";

const MyAssignedCampaign = () => {
  const { campaignId } = useParams();

  const navigate = useNavigate();

  const [assigned, setAssigned] = useState("");
  const [campaign, setCampaign] = useState("");
  const [video, setVideo] = useState("");
  const [showCounterForm, setShowCounterForm] = useState(false);
  const [cos, setcos] = useState(false);
  const [accepted, setaccepted] = useState(false);
  const [formloader, setformloader] = useState(false);
  const [informerror, setinformerror] = useState("");
  const [newbudget, setnewbudget] = useState("");
  const [newdeadline, setnewdeadline] = useState("");
  const [newnote, setnewnote] = useState("");
  const { socket, fetchUnreadMessages, getmyassignedcampaign, addcounteroffer, acceptoffer, submitvideo, isLoading } = useContext(AppContext);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [videolink, setvideolink] = useState("");

  const fetchAssignedCampaign = async () => {
    const response = await getmyassignedcampaign(campaignId);
    if (response) {
      if (response["assigned"].length > 0) {
        setAssigned(response["assigned"][0]);
        setCampaign(response["campaign"][0]);
        setVideo(response["video"].length > 0 ? response["video"][0] : "");
        setnewbudget(assigned.budget);
        setnewdeadline(assigned.deadline);
      } else {
        navigate("/assigned-campaigns");
      }
    } else {
      navigate("/assigned-campaigns");
    }
  };

  useEffect(() => {
    if (campaignId) {
      fetchAssignedCampaign();
    }
  }, []);

  useEffect(() => {
    if (socket && campaignId) {
      function getConversationId(campaignId, userId1, userId2) {
        return [campaignId, userId1, userId2].sort().join("-");
      }

      const convid = getConversationId(campaignId, userToken.userid, "");

      socket.emit("join conversation", { sender: userToken.userid, receiver: "", campaignId: campaignId });

      // Fetch old chat messages
      socket.emit("fetch messages", {
        sender: userToken.userid,
        usertype: userToken.type,
        receiver: "",
        campaignid: campaignId,
      });

      // Receive old chat messages
      socket.on("old messages", (data) => {
        setMessages(data);
        fetchUnreadMessages();
      });

      // Receive new chat message
      socket.on("chat message", (data) => {
        const { sender, receiver, message } = data;

        // Check if the received message is for the selected conversation

        setMessages((prevMessages) => [...prevMessages, data]);
      });

      return () => {
        socket.off("chat message");
        socket.off("old messages");
        socket.emit("leave conversation", userToken.userid);
      };
    }
  }, [socket, campaignId]);

  const handlecounteroffer = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      try {
        if (newbudget !== "" && newdeadline !== "") {
          setinformerror("");

          let response = await addcounteroffer({ newbudget, newdeadline, newnote, campaignId });

          if (response === "success") {
            setcos(true);
            setShowCounterForm(false);
            fetchAssignedCampaign();
          }
        } else {
          setinformerror("Budget & Deadline are required");
          return;
        }
      } catch (error) {
        setinformerror("Error: " + error);
      }
    }
  };

  const handleacceptoffer = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to accept?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Accept!",
        });

        if (result.isConfirmed) {
          let response = await acceptoffer(campaignId);

          if (response === "success") {
            setaccepted(true);
            fetchAssignedCampaign();
          }
        }
      } catch (error) {
        setinformerror("Error: " + error);
      }
    }
  };

  const handlevideosubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      try {
        if (videolink !== "") {
          const result = await Swal.fire({
            title: "Please make sure the link is correct",
            text: "You won't be able to undo this action",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Submit",
          });

          if (result.isConfirmed) {
            setformloader(true);
            let response = await submitvideo(videolink, campaignId);

            if (response === "success") {
              setformloader(false);
              fetchAssignedCampaign();
            }
          }
        }
      } catch (error) {
        setinformerror("Error: " + error);
      }
    }
  };

  const sendMessage = () => {
    const receiver = "";
    console.log(messageText.trim());
    socket.emit("chat message", {
      title: campaign.title,
      campaignId,
      sender: userToken.userid,
      receiver,
      message: messageText.trim(),
    });
    setMessageText("");
  };

  return (
    <AppLayout pageName="Campaign Details">
      {campaign !== "" ? (
        <>
          {(assigned.status === "Work in Progress" || assigned.status === "Re-Submit Video") && (
            <>
              {assigned.status === "Work in Progress" ? (
                <div id="alert-additional-content-1" className="p-5 mb-2 text-main border border-blue-100 rounded-lg bg-blue-50" role="alert">
                  <div className="flex items-center">
                    <h3 className="text-xl font-semibold">Thank you for accepting our offer</h3>
                  </div>
                  <div className="mt-2 text-base text-medium">Goodluck working on the video, please read the instructions carefully and keep the deadline in mind. Looking forward to your work.</div>
                </div>
              ) : (
                <div id="alert-additional-content-1" className="p-5 mb-2 text-red-600 border border-red-100 rounded-lg bg-red-50" role="alert">
                  <div className="flex items-center">
                    <h3 className="text-xl font-semibold">Last Video Submission Rejected. Please Re-Submit.</h3>
                  </div>
                  <div className="mt-2 text-base text-medium">
                    Please check the Questions box for more clarification. <br />
                    Goodluck working on the video, please read the instructions carefully and keep the deadline in mind. Looking forward to your work.
                  </div>
                </div>
              )}

              <section>
                <h2 className="text-2xl mb-5 font-semibold text-main tracking-normal">Video Submission</h2>
                <form onSubmit={handlevideosubmit} className="flex flex-row space-x-5">
                  <input className="form-control" type={"url"} required name="videolink" value={videolink} onChange={(e) => setvideolink(e.target.value)} placeholder="https://www.tiktok.com/@f1" />
                  <button type="submit" className="btn-submit" disabled={formloader}>
                    {formloader === true ? (
                      <div>
                        <Spinner animating={formloader} />
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </section>
            </>
          )}

          {campaign.status === "Completed" && (
            <div id="alert-additional-content-1" className="p-5 mb-2 text-main border border-blue-100 rounded-lg bg-blue-50" role="alert">
              <div className="flex items-center">
                <h3 className="text-2xl tracking-wide font-semibold">This campaign has been completed</h3>
              </div>
              <div className="mt-1 text-base text-medium">Thank you for working with us. Your payment has been added to your wallet.</div>
            </div>
          )}

          {video !== "" && (
            <>
              <section className="space-y-5">
                <h2 className="text-2xl font-semibold text-main tracking-normal">Video Submission</h2>
                {assigned.status === "Reviewing Video" && (
                  <div>
                    <div className="p-4 text-sm text-yellow-500 rounded-lg bg-yellow-50 border border-yellow-100 font-medium" role="alert">
                      Video Under Review
                    </div>
                  </div>
                )}
                <div className="block md:flex flex-row md:space-x-5">
                  <div className="flex flex-1">
                    <a className="w-full" target={"_blank"} href={video.video}>
                      <div className="p-3 flex flex-1 items-start md:items-center px-4 border border-neutral-200 rounded-lg w-full overflow-hidden">
                        <FaLink className="mr-3 hidden md:block" color="#333" size={20} /> <span className="break-all">{video.video}</span>
                      </div>
                    </a>
                  </div>
                  <a target={"_blank"} href={video.video} className="btn-submit-alt !hidden md:!flex">
                    Visit Link
                  </a>
                </div>
                {video.likes !== null ? (
                  <div className="flex flex-row space-x-5">
                    <div className="w-1/5">
                      <img className="w-full rounded-lg" src={video.image} />
                    </div>
                    <div className="w-4/5">
                      <div className="flex flex-1 h-full flex-col justify-between">
                        <div>
                          <h2 className="text-xl mb-5 font-semibold text-neutral-900 tracking-normal">Video Overview</h2>
                          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            <div>
                              <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Views</h6>
                              <h2 className="text-lg font-semibold tracking-normal">{video.views}</h2>
                            </div>
                            <div>
                              <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Likes</h6>
                              <h2 className="text-lg font-semibold tracking-normal">{video.likes}</h2>
                            </div>
                            <div>
                              <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Shares</h6>
                              <h2 className="text-lg font-semibold tracking-normal">{video.shares}</h2>
                            </div>
                            <div>
                              <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Comments</h6>
                              <h2 className="text-lg font-semibold tracking-normal">{video.comments}</h2>
                            </div>
                          </div>
                          <div className="mt-5">
                            <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Caption</h6>
                            <h2 className="text-base font-medium tracking-normal">{video.caption}</h2>
                          </div>
                        </div>
                        <div>
                          <div className="text-neutral-400 text-xs mb-1 pt-5">
                            Last Updated: <span className="font-medium">{video.analyticsupdated}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="p-5 mb-3 text-main border border-blue-100 rounded-lg bg-blue-50 flex space-x-3 font-medium items-center">
                      <CgSpinner className="spinner text-xl" /> <span>Fetching Video Analytics. Please check back in few minutes...</span>
                    </div>
                  </>
                )}
              </section>
            </>
          )}

          <section>
            <div className="grid grid-cols-2 gap-5">
              <div>
                <h6 className="text-sm font-normal text-neutral-500 mb-0 leading-none">Title</h6>
                <h2 className="text-lg font-semibold tracking-normal">{campaign.title}</h2>
              </div>
              <div>
                <h6 className="text-sm font-normal text-neutral-500 mb-0 leading-none">Status</h6>
                <h2 className="text-lg font-semibold tracking-normal">{assigned.status}</h2>
              </div>
              <div>
                <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Budget</h6>
                <h2 className="text-base font-semibold tracking-normal">${assigned.budget.toLocaleString("en-US")}</h2>
              </div>
              <div>
                <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Deadline</h6>
                <h2 className="text-base font-semibold tracking-normal">{assigned.deadlinedate}</h2>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-5 mt-5">
              <div>
                <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Song Link</h6>
                <h2>
                  <a className="text-base font-semibold tracking-normal text-main hover:!underline" target={"_blank"} href={assigned.song}>
                    {assigned.song}
                  </a>
                </h2>
              </div>
              <div>
                <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Description</h6>
                <h2 className="text-base font-medium tracking-normal">{assigned.description}</h2>
              </div>
            </div>
            {assigned.status === "Awaiting Approval" ? (
              <>
                <div className="flex space-x-3 mt-4 pt-5">
                  {!showCounterForm && (
                    <button className="btn btn-submit" onClick={handleacceptoffer}>
                      Accept the Offer <IoCheckmarkDone size={20} />
                    </button>
                  )}
                  <button className={`btn btn-submit-alt ${showCounterForm && "!border-red-600 !text-red-600"} `} onClick={() => setShowCounterForm(!showCounterForm)}>
                    Counter-offer <HiArrowNarrowRight />
                  </button>
                </div>

                <div className="mt-7">
                  <div id="alert-additional-content-1" class={`p-4 mb-4 ${showCounterForm ? "" : "hidden"} text-main border border-main rounded-lg bg-blue-50`} role="alert">
                    <div className="flex items-center">
                      <svg className="flex-shrink-0 w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"></path>
                      </svg>
                      <span className="sr-only">Info</span>
                      <h3 className="text-xl font-semibold">Counter Offer Proposal</h3>
                    </div>
                    <div className="mt-2 text-base leading-loose tracking-wide pl-6">
                      For counter offer proposal or any questions about this campaign, please send your queries in the chat box below.
                      <br />
                      We'll get back to you as soon as possible. Thank you!
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {assigned.status === "Counter-Offer Submitted" ? (
              <div className="mt-6 pt-6 border-t border-neutral-200">
                <h2 className="text-2xl font-semibold text-main tracking-normal">Proposed Counter-Offer</h2>

                <div className="grid grid-cols-2 gap-5 mt-5">
                  <div>
                    <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">New Budget</h6>
                    <h2 className="text-base font-semibold tracking-normal">${assigned.newbudget.toLocaleString("en-US")}</h2>
                  </div>
                  <div>
                    <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">New Deadline</h6>
                    <h2 className="text-base font-semibold tracking-normal">{assigned.newdeadlinedate}</h2>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-5 mt-5">
                  <div>
                    <h6 className="text-sm font-normal text-neutral-500 mb-1 leading-none">Note/Reason</h6>
                    <h2 className="text-base font-medium tracking-normal">{assigned.newnote}</h2>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-main tracking-normal mb-1">Ask Questions</h2>
            <ScrollToBottom className="flex flex-1 w-full overflow-y-hidden mb-3 chatbox bg-[#FFF] h-96 border border-neutral-200 rounded-lg">
              <ChatBox messages={messages} />
            </ScrollToBottom>
            <div>
              <div className="flex w-full border border-main rounded-lg mb-0">
                <input
                  type="text"
                  className="flex-1 p-3 bg-transparent rounded-lg mr-2 text-neutral-800 hover:outline-none focus:outline-none"
                  placeholder="Type your message"
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  onKeyPress={(event) => {
                    event.key === "Enter" && sendMessage();
                  }}
                />
                <button type="button" className="bg-main text-white rounded-r-md p-3 px-4" onClick={sendMessage}>
                  <VscSend color="#FFF" size={24} />
                </button>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default MyAssignedCampaign;
