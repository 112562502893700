import React, { Fragment, useContext, useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from "../config";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Modal from "../components/Modal";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { PiPlusCircle } from "react-icons/pi";
import "../styles/monitor.scss";
import Swal from 'sweetalert2';
import { HiMiniChevronDown } from "react-icons/hi2";
import { GoDotFill } from "react-icons/go";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import ApexCharts from 'react-apexcharts';

const Analytics = () => {
  const { isLoading } = useContext(AppContext);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [showNewModal, setshowNewModal] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [upgraded, setUpgraded] = useState(false);
  const [data, setData] = useState([]);
  const [topFourData, setTopFourData] = useState([]);
  const [totals, setTotals] = useState({
    totalMentions: 0,
    negativeMentions: 0,
    positiveMentions: 0,
    neutralMentions: 0,
  });
  const [series, setSeries] = useState([totals.positiveMentions, totals.neutralMentions, totals.negativeMentions]);

  const processRows = (rows) => {
    let totalMentions = 0;
    let negativeMentions = 0;
    let positiveMentions = 0;
    let neutralMentions = 0;
    const currentYear = new Date().getFullYear();
    const processedData = rows.map((row, index) => {
      if (row[2].includes(year)) {
        totalMentions++;
        if (row[6] === 'Negative') negativeMentions++;
        else if (row[6] === 'Positive') positiveMentions++;
        else if (row[6] === 'Neutral') neutralMentions++;

        return {
          article: row[0],
          title: row[0].length > 27 ? `${row[0].substring(0, 27)}...` : row[0],
          date: row[2],
          author: row[4] || '-',
          ave: row[7] ? `$ ${parseFloat(row[7].replace('$', '').replace(',', '')).toFixed(2)}` : '$ 0',
          sentiment: row[6],
          shares: row[5],
          link: row[8],
        };
      }
      return null;
    }).filter(row => row !== null);

    setData(processedData);
    setTotals({ totalMentions, negativeMentions, positiveMentions, neutralMentions });

    const sortedData = processedData.sort((a, b) => b.ave - a.ave).slice(0, 4);
    setTopFourData(sortedData);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/sheet-data/` + userToken.userid);
      processRows(response.data.rows);
    } catch (error) {
      console.error('Error fetching data from backend:', error);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    setTableLoading(true);
    fetchData();
    setShowYearDropdown(false);

  }, [year]); 
  
  
  useEffect(() => {
    setSeries([totals.positiveMentions, totals.neutralMentions, totals.negativeMentions]);
  }, [totals.positiveMentions, totals.neutralMentions, totals.negativeMentions]);
  
  useEffect(() => {
    console.log(totals);
  }, [totals]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      // fetchInfluencers(listId);
    }
  };

  var years = ['2023','2024'];

  var chartoptions = {chart: {
      fontFamily: "Euclid Circular A, Sans-serif",
      redrawOnParentResize: true,
      width: '100%',
      type: 'donut',
    },
    labels: ['Positive', 'Neutral', 'Negative'],
    dataLabels: {
      enabled: true,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '80%',
        },
        legend: {
          show: false,
        },
      },
    }],
    legend: {
      show: false,
    },
    colors: ['#38C477', '#CCC', '#F2543D'],
  }



  return (
    <AppLayout pageId="monitor" pageName={"Analytics"}>
      <section>
        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <>
          { upgraded ? 
          <>
          <div className="block xl:flex flex-row">
            <div className="w-full xl:w-4/6">
                <div className="section-left">
                  <div className="flex justify-between">
                      <div className="page-head">
                          <h4>Analytics</h4>
                      </div>
                      <div>
                        <div className="relative mx-auto">
                          <button class={`btn-main-alt whitespace-nowrap flex items-center justify-between space-x-4 ${showYearDropdown && '!rounded-b-none !rounded-t-xl border border-[#666666]'}`} onClick={() => setShowYearDropdown(!showYearDropdown)}>
                            <span>Fiscal year &nbsp; | &nbsp; {year}</span> <HiMiniChevronDown size={18}/>
                          </button>
                          { showYearDropdown && 
                          <div className="dropdown-menu">
                            {
                            years.map((y, index) => {
                              return(
                                <div className={`dropdown-item`} onClick={() => setYear(y)}>{y}</div>
                              )
                            })
                            }
                          </div> 
                          }
                        </div>
                      </div>
                  </div>
                  {tableLoading ? (
                    <div className="flex justify-center w-full py-12">
                      <Spinner color="#CCCCCC" size={24} />
                    </div>
                  ) : (
                  <>
                      <div className="flex justify-between items-center mt-10">
                          <div>
                              <h4 className="text-2xl"><b>{data.length}</b> mentions in total</h4>
                          </div>
                          <div className="flex justify-end space-x-6">
                              <button className={`btn-main-alt whitespace-nowrap`}>Export</button>
                              <button className={`btn-main-alt whitespace-nowrap`}>Edit Searches</button>
                          </div>
                      </div>
                      <div className="table-wrapper flex-1 mt-5" onScroll={handleScroll}>
                        <table className="table main-table">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Date</th>
                              <th>Author</th>
                              <th>AVE</th>
                              <th>Sentiment</th>
                              <th>Shares</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row, index) => (
                              <tr key={index} onClick={() => window.open(row.link, '_blank')}>
                                <td>{row.title}</td>
                                <td>{row.date}</td>
                                <td>{row.author}</td>
                                <td>{row.ave}</td>
                                <td className={row.sentiment}><span className="font-semibold">{row.sentiment}</span></td>
                                <td>{row.shares}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                  </>
                  )
                  }
              </div>
            </div>
            <div className="w-full xl:w-2/6 lg:pl-10">
              <div className="section-right bg-main-alt rounded-2xl p-10 space-y-10">
                <div>
                  <h4 className="text-2xl">Highest AVE</h4>
                  <div className="table-wrapper flex-1 mt-5" onScroll={handleScroll}>
                    <table className="table ave-table">
                      <thead>
                        <tr>
                          <th>Article</th>
                          <th>AVE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topFourData.map((row, index) => (
                          <tr key={index} onClick={() => window.open(row.link, '_blank')}>
                            <td className="text-sm whitespace-normal align-top !py-3 !pt-3.5">{row.article}</td>
                            <td className="!py-3">{row.ave.replace("$","")}<br/><span class="text-[#51C97F]">$</span></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <h4 className="text-2xl">Sentiment</h4>
                  <div className="donutCell">
                    <div className="relative">
                    <div>
                      <ApexCharts options={chartoptions} series={series} type="donut" />
                    </div>
                    <div className="absolute w-full top-[40%] text-center">
                      <div>
                        <div className="text-3xl leading-none font-bold">{totals.totalMentions !== 0 ? ((totals.positiveMentions * 100) / totals.totalMentions).toFixed(0) : 0}%</div>
                        <span className="text-sm text-semibold">Positive</span>
                      </div>
                    </div>
                    </div>
                    <div className="text-center font-weight-600 text-black text-sm flex flex-wrap justify-center items-center space-x-6">
                      <div className="flex items-center my-3">
                        <GoDotFill className="Positive mr-1" size={20}/> Positive
                      </div>
                      <div className="flex items-center my-3">
                        <GoDotFill className="Neutral mr-1" size={20}/> Neutral
                      </div>
                      <div className="flex items-center my-3">
                        <GoDotFill className="Negative mr-1" size={20}/> Negative
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
          : <>
          <div className="flex justify-between">
              <div className="page-head">
                  <h4>Analytics</h4>
              </div>
              <div>
                  <button className={`btn-new flex items-center !px-6`}><HiOutlineRocketLaunch size={14} className="mr-2.5"/> Upgrade</button>
              </div>
          </div>
          <div className="text-center space-y-8 mt-20">
            <img className="mx-auto max-w-8/12" src="/images/no-analytics.png"/>
            <div className="text-black space-y-2">
              <h4 className="text-xl font-semibold">Analytics your mentions, audience &amp; demographics</h4>
              <h5 className="text-base">Upgrade your account to get started</h5>
            </div>
            <div>
              <button className={`btn-new flex items-center mx-auto !px-8`} onClick={() => setshowNewModal(true)}><HiOutlineRocketLaunch size={14} className="mr-2.5"/> Upgrade</button>
            </div>
          </div>
          </> }
          </>
        )}
      </section>
    </AppLayout>
  );
};

export default Analytics;
