import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/auth.scss";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { MdOutlineCopyright } from "react-icons/md";

const updateThemeColor = (color) => {
  // Find the meta tag or create one if it doesn't exist
  let themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
  if (!themeColorMetaTag) {
    themeColorMetaTag = document.createElement("meta");
    themeColorMetaTag.setAttribute("name", "theme-color");
    document.getElementsByTagName("head")[0].appendChild(themeColorMetaTag);
  }

  // Update the content attribute to the new color
  themeColorMetaTag.setAttribute("content", color);
};

const AuthLayout = ({ page, children, heading, subheading }) => {
  useEffect(() => {
    updateThemeColor("#3850D8");
  }, []);

  return (
    <div className="auth-screens">
      <div className="block md:flex flex-col overflow-hidden h-full flex-1 justify-between">
        <div>
          <div className="w-full mb-8 relative">
            <div className="flex justify-center">
              <Link to={"/"}>
                <img className="h-9 mx-auto" alt="Poppiq Logo" src="/logo.png" />
              </Link>
            </div>
            { page == 'Login' &&
              <div className="absolute right-0 top-0">
                <div class="text-right text-neutral-700 text-sm font-medium">
                  <div>New to Poppiq?</div>
                  <Link className="text-blue-500 font-semibold" to="/register">Create an account</Link>
                </div>
              </div>
            }
          </div>
          <div className="text-center">
            <h1 className="heading">{heading}</h1>
            <h6 className="subheading" dangerouslySetInnerHTML={{ __html: subheading }} />
          </div>
          {/* <div className="w-full md:w-2/3 section-left">
            <div className="bg-overlay w-10/12 mx-auto">
              <div className="w-full pt-3">
                <div className="section-left-content">
                  <h2>Get started for free</h2>
                  <h5>Sign up and effortlessly scale your brand</h5>
                </div>
              </div>
              <div className="w-full hidden md:block mt-10">
                <div class="text">
                    <div>
                        &copy; 2024 <span class="text-neutral-100">Poppiq</span>
                    </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="w-full max-w-[30rem] section-right mx-auto p-5">
            <div class="form-section p-10x">
              <div className="pt-0 md:pt-5 pb-5 h-full flex flex-col justify-between mx-auto w-full max-w-[30rem] space-y-10">
                {/* <div className="w-full flex flex-1 flex-col justify-between"> */}
                  {children}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-6 justify-center text-neutral-300 text-sm">
          <div>
            <Link className="hover:!underline">
              Privacy
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Terms
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Cookies
            </Link>
          </div>
          <div>
            <Link className="hover:!underline">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
