import React, { useState, useContext, useRef, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import { Link } from "react-router-dom";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import "../styles/settings.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import { AppContext } from "../contexts/AppContext";
import { VscOrganization } from "react-icons/vsc";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineUser } from "react-icons/ai";
import { LiaPhoneSolid } from "react-icons/lia";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import "react-phone-number-input/style.css";
import { PiPaypalLogo } from "react-icons/pi";
import PhoneInput from "react-phone-number-input";

export default function MyProfile() {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [name, setName] = useState(userToken.name);
  const [lastname, setLastname] = useState(userToken.lastname);
  const [company, setCompany] = useState(userToken.company);
  const [phone, setPhone] = useState(userToken.phone);
  const [email, setEmail] = useState(userToken.email);
  const [type, setType] = useState(userToken.type);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [imageset, setImageSet] = useState(false);
  const [editimage, setEditimage] = useState(false);
  const profilePictureRef = useRef(null);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const { updateprofile, updateprofilepicture, isLoading } = useContext(AppContext);

  useEffect(() => {
    if (userToken.image !== null && userToken.image !== "") {
      const PP = profilePictureRef.current;
    }
  }, [userToken]);

  useEffect(() => {
    if(userToken.name.type === 'Admin'){
      if (name !== userToken.name || email !== userToken.email) {
        setIsFormChanged(true);
      } else {
        setIsFormChanged(false);
      }
    }
    else{
      if (company !== userToken.company || name !== userToken.name || lastname !== userToken.lastname || phone !== userToken.phone) {
        setIsFormChanged(true);
      } else {
        setIsFormChanged(false);
      }
    }
  }, [company, userToken.company, name, userToken.name, lastname, userToken.lastname, phone, userToken.phone]);

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    if (isLoading === false) {
      try {
        if (imageAsDataURL !== "") {
          setError2("");
          const response = await updateprofilepicture(imageAsDataURL);
          if (response === "success") {
            setImageSet(false);
            setEditimage(false);
          }
        } else {
          setError2("An error occurred");
          return;
        }
      } catch (error) {
        setError2("Update failed: " + error);
      }
    }

    //add here the upload logic...
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false && isFormChanged === true) {
      try {
        if (name !== "" && email !== "") {
          setError("");
          setIsFormChanged(false);
          const response = await updateprofile(company, name, lastname, email, phone);
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  return (
    <AppLayout pageName={"My Profile"}>
      <section>
        <main className="settings">
          <div className="flex flex-row space-x-10 p-10 main-bg rounded-lg">
            <form onSubmit={handleSubmit} className="space-y-5 w-2/3">
              <div className="form-group flex flex-row items-center company">
                <div className="w-1/4">
                  <label htmlFor="company">Company Name</label>
                </div>
                <div className="w-3/4">
                <input id="company" name="company" required type="text" className="form-control" placeholder="Poppiq" value={company} onChange={(e) => setCompany(e.target.value)} />
                </div>
              </div>
              <div className="form-group flex flex-row items-center name">
                <div className="w-1/4">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="w-3/4">
                  <div className="flex space-x-4">
                    <div className="form-group w-1/2 name">
                      <input id="name" name="name" required type="text" className="form-control" placeholder="John" value={name} onChange={(e) => setName(e.target.value)} maxLength={50} />
                      <div className="text-xs">First</div>
                    </div>
                    <div className="form-group w-1/2 lastname">
                      <input id="lastname" name="lastname" required type="text" className="form-control" placeholder="Doe" value={lastname} onChange={(e) => setLastname(e.target.value)} maxLength={50} />
                      <div className="text-xs">Last</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`form-group flex flex-row items-center email`}>
                <div className="w-1/4">
                  <label htmlFor="email">Email Address</label>
                </div>
                <div className={`w-3/4 relative ${type !== "Admin" && "opacity-50"}`}>
                  <input id="email" name="email" required type="email" className="form-control" placeholder="hello@poppiq.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} readOnly={type !== "Admin" ? true : false} />
                  <div className="absolute right-3 top-1.5">{type !== "Admin" && <span className="text-red-600 font-medium text-sm">Can't edit</span>}</div>
                </div>
              </div>
              <div className="form-group flex flex-row items-center phone">
                <div className="w-1/4">
                  <label htmlFor="phone">Phone Number</label>
                </div>
                <div className="w-3/4">
                  <input id="phone" name="phone" required type="tel" className="form-control" placeholder="(123) 456 7890" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={20} />
                </div>
              </div>
              <div>
                <div className="flex flex-row">
                  <div className="w-1/4">
                  </div>
                  <div className="w-3/4">
                    {error !== "" ? <div className="text-red-500 font-semibold text-md mb-2">{error}</div> : <div className="py-3"></div>}
                    <button type="submit" className="btn-submit text-center justify-center !py-4" disabled={isLoading || !isFormChanged}>
                      {isLoading === false ? "Save Changes" : <Bounce className="my-2" animating={isLoading} />}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="mb-5 w-1/3">
              <div className="flex flex-row w-full justify-end">
                <button type="button" className={`btn-submit-inv !py-2 !px-4 !rounded-full ${editimage === true && "!bg-transparent !text-red-500 border !border-red-500"}`} onClick={() => setEditimage(!editimage)} disabled={isLoading}>
                  {editimage === true ? "Cancel" : "Edit Picture"}
                </button>
              </div>
              <div className="flex flex-col space-y-3">
                <div className="flex justify-center">
                  <div style={{ backgroundColor: "#FFF" }} className={`rounded-full ${editimage === false && "border shadow-md"} `}>
                    {editimage === true ? <ProfilePicture ref={profilePictureRef} onImageLoaded={() => setImageSet(true)} onImageRemoved={() => setImageSet(false)} cropSize={150} frameFormat="circle" /> : ( userToken.image != '' ? <img className="rounded-full object-contain w-[150px] h-[150px]" src={`data:image/png;base64,${userToken.image}`} /> : <div className="w-[150px] h-[150px] flex justify-center items-center bg-white rounded-full text-4xl text-neutral-400"><span>{userToken.name[0]+(userToken.lastname != '' ? userToken.lastname[0] : '')}</span></div> )}
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="space-y-3">
                    {error2 !== "" && <div className="text-center text-red-500 font-semibold text-md">{error2}</div>}
                    <div>
                      {imageset === true && (
                        <button type="button" className="btn-main !py-3 !px-8" onClick={handleUpload} disabled={isLoading}>
                          {isLoading === false ? "Set Picture" : <Bounce animating={isLoading} />}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </AppLayout>
  );
}
