import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { FiAtSign, FiDivide } from "react-icons/fi";
import Tooltip from "../components/Tooltip";
import Swal from "sweetalert2";
import axios from "axios";
import { HiOutlineLocationMarker } from "react-icons/hi";
import "../styles/analytics.css";
import { Bar } from "react-chartjs-2";
import "chart.js/auto"; // Necessary for Chart.js 3.x

const InfluencerAnalytics = () => {
  const { getmycampaigns, isLoading } = useContext(AppContext);

  const [analytics, setAnalytics] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [data, setData] = useState([]);
  const [pageLoading, setpageLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [username, setUsername] = useState("mrbeast");
  const [HashtagsCount, sethashtagsCount] = useState(0);
  const [MentionsCount, setmentionsCount] = useState(0);
  const [chartData, setChartData] = useState();
  const [chartOptions, setChartOptions] = useState();
  const genericSearch = (items, searchTerm) => {
    if (!searchTerm) {
      return items;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(lowerCaseSearchTerm)));
  };

  const handleSearch = async () => {
    try {
      if (username == "") {
      } else {
        setpageLoading(true);
        const response = await axios.get(`http://localhost:3002/api/fetchuseranalytics/` + username);
        if (response.status === 200 && response.data) {
          setAnalytics(response.data);

          const hashtagsCounts = response.data.mostusedhashtags.map((hashtag) => {
            const parts = hashtag.match(/\d+/g);
            return parts ? parseInt(parts[0], 10) : 0;
          });

          const mentionsCounts = response.data.mostmentions.map((mention) => {
            const parts = mention.match(/\d+/g);
            return parts ? parseInt(parts[0], 10) : 0;
          });

          sethashtagsCount(Math.max(...hashtagsCounts, 0));
          setmentionsCount(Math.max(...mentionsCounts, 0));

          const chartdata = {
            labels: response.data.recentPosts.map((data, index) => `${index + 1}`),
            datasets: [
              {
                label: "Post Engagement",
                data: response.data.recentPosts.map((data) => data.engagementRate.replaceAll("%", "")),
                backgroundColor: "#85CAFF",
                borderColor: "#008BF5",
                borderWidth: 1,
              },
            ],
          };

          const chartoptions = {
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
                beginAtZero: true,
              },
            },
          };

          setChartData(chartdata);
          setChartOptions(chartoptions);
          setFetched(true);
        }
      }
    } catch (error) {
      Swal.fire("An Error Occurred", error, "error");
    } finally {
      setpageLoading(false);
    }
  };

  return (
    <AppLayout pageName={"Influencer Analytics"}>
      <section>
        <div className="w-full my-0 md:my-10 md:w-10/12 lg:w-8/12 mx-auto">
          <div class="input-group">
            <label htmlFor="statusFilter">
              <FiAtSign size={20} color="#444444" />
            </label>
            <input className="form-control flex flex-1" placeholder="Enter Tiktok Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <button className="btn btn-main !rounded-l-none" disabled={pageLoading} onClick={handleSearch}>
              {pageLoading ? <Spinner color="#CCCCCC" size={10} /> : "Search"}
            </button>
          </div>
        </div>
      </section>

      {pageLoading ? (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      ) : (
        <>
        { fetched && 
          <>
            {analytics !== "" ? (
              <div className="analytics">
                <div className="">
                  <div className="w-full">
                    <section>
                      <div className="space-y-3 py-3 text-center">
                        <img src={analytics.image} className="w-36 h-36 mx-auto rounded-full border" />
                        <div>
                          <h4 className="text-xl text-black font-semibold tracking-wide mb-1">{analytics.name}</h4>
                          <div className="text-base text-neutral-600 font-medium flex space-x-1 justify-center items-center">
                            <HiOutlineLocationMarker /> <span>{analytics.location}</span>
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <a className="btn btn-main-inverse">Visit Profile</a>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="">
                    <div className="w-full grid grid-data gap-2 grid-cols-2 lg:grid-cols-4 text-center">
                      <section>
                        <h2 className="stat-value">{analytics.followers}</h2>
                        <h6 className="stat-heading">Total Followers</h6>
                      </section>
                      <section>
                        <h2 className="stat-value">{analytics.likes}</h2>
                        <h6 className="stat-heading">Total Likes</h6>
                      </section>
                      <section>
                        <h2 className="stat-value">{analytics.videos}</h2>
                        <h6 className="stat-heading">Total Videos</h6>
                      </section>
                      <section>
                        <h2 className="stat-value">{analytics.following}</h2>
                        <h6 className="stat-heading">Total Following</h6>
                      </section>
                    </div>
                  </div>
                  <section className="">
                    <h2 className="text-2xl text-main font-medium text-center mb-6 mt-2">Total Engagement Rates</h2>
                    <div className="w-full grid grid-data gap-4 grid-cols-2 lg:grid-cols-4 divide-x divide-neutral-200 text-center">
                      <div>
                        <h6 className="stat-heading">Overall Engagement</h6>
                        <h2 className="stat-value">{analytics.overallengagement}</h2>
                      </div>
                      <div>
                        <h6 className="stat-heading">Likes Rate</h6>
                        <h2 className="stat-value">{analytics.likesrate}</h2>
                      </div>
                      <div>
                        <h6 className="stat-heading">Comments Rate</h6>
                        <h2 className="stat-value">{analytics.commentsrate}</h2>
                      </div>
                      <div>
                        <h6 className="stat-heading">Shares Rate</h6>
                        <h2 className="stat-value">{analytics.sharesrate}</h2>
                      </div>
                    </div>
                  </section>
                  <section className="">
                    <h2 className="text-2xl text-main font-medium text-center mb-6 mt-2">Average Video Performance</h2>
                    <div className="w-full grid grid-data gap-4 grid-cols-2 lg:grid-cols-4 divide-x divide-neutral-200 text-center">
                      <div>
                        <h6 className="stat-heading">Average Views</h6>
                        <h2 className="stat-value">{analytics.avgviews}</h2>
                      </div>
                      <div>
                        <h6 className="stat-heading">Average Likes</h6>
                        <h2 className="stat-value">{analytics.avglikes}</h2>
                      </div>
                      <div>
                        <h6 className="stat-heading">Average Comments</h6>
                        <h2 className="stat-value">{analytics.avgcomments}</h2>
                      </div>
                      <div>
                        <h6 className="stat-heading">Average Shares</h6>
                        <h2 className="stat-value">{analytics.avgshares}</h2>
                      </div>
                    </div>
                  </section>
                  <div className="grid grid-cols-2 gap-2">
                    <section>
                      <h2 className="text-2xl text-main font-medium text-center mb-6 mt-2">Hashtags</h2>
                      <div className="flex flex-wrap tags">
                        {analytics.hashtags.map((hashtag, index) => {
                          return <div key={index}>{hashtag}</div>;
                        })}
                      </div>
                    </section>
                    <section>
                      <h2 className="text-2xl text-main font-medium text-center mb-6 mt-2">Most Used Hashtags</h2>
                      <div className="flex flex-col bars space-y-2">
                        {analytics.mostusedhashtags.map((hashtag, index) => {
                          const n = hashtag.replace("(", "").replace(")", "").split(" ");
                          const count = parseInt(n[1], 10);
                          return (
                            <div key={index}>
                              <div style={{ width: `${(count / HashtagsCount) * 100}%` }}>
                                {hashtag} - {n[1]}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </section>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <section>
                      <h2 className="text-2xl text-main font-medium text-center mb-6 mt-2">Mentions</h2>
                      <div className="flex flex-wrap tags">
                        {analytics.mentions.map((mention, index) => {
                          return <div key={index}>{mention}</div>;
                        })}
                      </div>
                    </section>
                    <section>
                      <h2 className="text-2xl text-main font-medium text-center mb-6 mt-2">Most Mentions</h2>
                      <div className="flex flex-col bars space-y-2">
                        {analytics.mostmentions.map((mention, index) => {
                          const n = mention.replace("(", "").replace(")", "").split(" ");
                          const count = parseInt(n[1], 10);
                          return (
                            <div key={index}>
                              <div style={{ width: `${(count / MentionsCount) * 100}%` }}>
                                {mention} - {n[1]}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </section>
                  </div>
                  <section>
                    <h2 className="text-2xl text-main font-medium text-center mb-6 mt-3">
                      Average Engagement Rates <small>( last 20 posts )</small>
                    </h2>
                    <div className="mb-4 h-96">
                      <Bar data={chartData} options={chartOptions} />
                    </div>
                  </section>
                  <section>
                    <h2 className="text-2xl text-main font-medium text-center mb-6 mt-3">Earnings (per post)</h2>
                    <div className="text-center mb-4">
                      <h3 className="text-3xl text-neutral-800 font-normal mb-2">
                        <b>{analytics.earnings.split(" / ")[0]}</b>
                      </h3>
                      <h6 className="text-neutral-400">Please note, this estimation may significantly vary depending on niche, country, and audience location.</h6>
                    </div>
                  </section>
                  <section>
                    <h2 className="text-2xl text-main font-medium text-center mb-6 mt-2">Recent Posts</h2>
                    <div className="mt-3 posts">
                      {analytics.recentPosts.map((post, index) => {
                        return (
                          <div key={index} className="py-2">
                            <section className="flex flex-row space-x-10">
                              <div className="w-[10%]">
                                <img className="w-full rounded-lg" src={post.imgSrc} />
                              </div>
                              <div className="w-[90%] flex flex-col space-y-6 justify-between pt-3">
                                <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-y-8">
                                  <div>
                                    <h6 className="stat-heading">Total Views</h6>
                                    <h2 className="stat-value">{post.views}</h2>
                                  </div>
                                  <div>
                                    <h6 className="stat-heading">Likes</h6>
                                    <h2 className="stat-value">{post.likes}</h2>
                                  </div>
                                  <div>
                                    <h6 className="stat-heading">Comments</h6>
                                    <h2 className="stat-value">{post.comments}</h2>
                                  </div>
                                  <div>
                                    <h6 className="stat-heading">Shares</h6>
                                    <h2 className="stat-value">{post.shares}</h2>
                                  </div>
                                  <div>
                                    <h6 className="stat-heading">Hashtags</h6>
                                    <h2 className="stat-value">{post.hashtags}</h2>
                                  </div>
                                  <div>
                                    <h6 className="stat-heading">Mentions</h6>
                                    <h2 className="stat-value">{post.mentions}</h2>
                                  </div>
                                  <div>
                                    <h6 className="stat-heading">Engagement Rate</h6>
                                    <h2 className="stat-value">{post.engagementRate}</h2>
                                  </div>
                                </div>
                                <div className="text-sm text-neutral-500">{post.createTime}</div>
                              </div>
                            </section>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </div>
              </div>
            ) : (
              <>
                <div className="text-red-600 text-xl font-medium py-12 text-center">An Error Occured! Please try again.</div>
              </>
            )}
          </>
          }
        </>
      )}
    </AppLayout>
  );
};

export default InfluencerAnalytics;
